import { Fragment } from "react";
import addReceipt from "../data/receipts/addReceipt";
import getReceipts from "../data/receipts/getReceipts";
import { useDataStorage } from "../data/useDataStorage";
import lastReceiptHasNOrMoreItems from "../data/validation/lastReceiptHasNOrMoreItems";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";
import TableCell from "../visual/TableCell";
import TableRow from "../visual/TableRow";
import TextButton, { GlyphAdd } from "../visual/TextButton";
import ReceiptTable from "./ReceiptTable";

const ReceiptsTable: React.FC<unknown> = (props) => {
  const storage = useDataStorage();
  const modal = useModalWindow();

  return (
    <>
      {storage.getData(getReceipts).map((receipt) => (
        <Fragment key={[receipt.id, storage.isEditing].join()}>
          <ReceiptTable receipt={receipt} />
        </Fragment>
      ))}

      {storage.isEditing &&
        (storage.getData(getReceipts).length > 1
          ? storage.getData(lastReceiptHasNOrMoreItems, 1)
          : storage.getData(lastReceiptHasNOrMoreItems, 2)) && (
          <TableRow>
            <TableCell>
              <TextButton
                data-error={
                  (storage.highlightErrors &&
                    storage.getData(getReceipts).length === 0) ||
                  undefined
                }
                onClick={async () => {
                  const shouldContinue =
                    storage.getData(getReceipts).length === 1
                      ? await modal.confirm(
                          "BillJoy currently only supports paying back one person per bill.\n\nOnly add another receipt to this bill if the same person covered it.\n\nIf you want to add a receipt that someone else covered, please create a separate bill.",
                          { okText: "Add receipt" },
                        )
                      : true;
                  if (!shouldContinue) return;

                  storage.setData(addReceipt, "");
                }}
              >
                {storage.getData(getReceipts).length === 0 ? (
                  <>
                    <GlyphAdd /> Step 2. Input the receipt(s)
                  </>
                ) : (
                  <>
                    <GlyphAdd /> Add another receipt
                  </>
                )}
              </TextButton>
            </TableCell>
          </TableRow>
        )}
    </>
  );
};

export default ReceiptsTable;
