import ocrImage from "./ocrImage";
import parseReceiptText from "./parseReceiptText";
import resizeImage from "./resizeImage";

export default async function getReceiptOcr(
  imageFile: File,
  onStateChange: (state: [number, string]) => void = () => undefined,
) {
  try {
    onStateChange([1, ""]);
    const resizedImageFile = await resizeImage(imageFile, 1024 * 1024);
    onStateChange([2, ""]);
    const text = await ocrImage(resizedImageFile);
    onStateChange([3, ""]);
    const result = await parseReceiptText(text);
    onStateChange([4, ""]);
    return result;
  } catch (e) {
    let message = e instanceof Error ? e.message : JSON.stringify(e);
    onStateChange([-1, message]);
    console.error(e);
  }
}
