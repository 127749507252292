import qrcode from "qrcode";
import { useEffect, useState } from "react";

export async function asyncQrCodeDataUrl(text: string): Promise<string> {
  return await qrcode.toDataURL(text, { scale: 20 });
}

export function useQrCodeDataUrl(text: string): string | undefined {
  const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    asyncQrCodeDataUrl(text).then(setDataUrl);
  }, [text]);

  return dataUrl;
}
