import styled from "@emotion/styled";

export default styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  overscroll-behavior: none;

  cursor: default;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden auto;

  outline: none;
  -webkit-tap-highlight-color: transparent;

  animation: 0.15s fadeIn ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
`;
