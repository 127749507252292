import Data from "../types/Data";
import Item from "../types/Item";

export default function addOrUpdateItem(
  data: Data,
  mode: "add",
  newItem: Item,
  receiptId: number,
  position?: number,
): Data;
export default function addOrUpdateItem(
  data: Data,
  mode: "update",
  partialItem: Partial<Item> & Pick<Item, "id">,
): Data;
export default function addOrUpdateItem(
  data: Data,
  mode: "add" | "update",
  itemOrPartialItem: Item | (Partial<Item> & Pick<Item, "id">),
  receiptId?: number,
  position?: number,
): Data {
  const item = {
    ...data.Item[itemOrPartialItem.id],
    ...itemOrPartialItem,
  };

  const receipt = receiptId != null ? data.Receipt[receiptId] : undefined;

  let Receipt = data.Receipt;

  if (mode === "add") {
    if (receiptId == null || receipt == null) {
      throw new Error("receiptId is required when adding an item");
    }

    const itemIds = [...receipt.itemIds];
    if (position == null) {
      position = itemIds.length;
    }
    itemIds.splice(position, 0, item.id);

    Receipt = {
      ...data.Receipt,
      [receiptId]: {
        ...receipt,
        itemIds,
      },
    };
  }

  return {
    ...data,
    Item: {
      ...data.Item,
      [item.id]: item,
    },
    Receipt,
    maxItemId: mode === "add" ? item.id : data.maxItemId,
  };
}
