import { createContext, useContext, useMemo, useState } from "react";
import DataBlank from "../data/DataBlank";
import recalculateTotals from "../data/recalculateTotals";
import Data from "../data/types/Data";

type SetData = React.Dispatch<React.SetStateAction<Data>>;

const DataStateContext = createContext<[Data, SetData]>(undefined!);

export const DataStateProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const dataState = useState<Data>(() => recalculateTotals(DataBlank));
  const memoizedDataState = useMemo(() => dataState, dataState);

  return (
    <DataStateContext.Provider value={memoizedDataState}>
      {props.children}
    </DataStateContext.Provider>
  );
};

export const useDataState = () => {
  const [data, setData] = useContext(DataStateContext);

  return [data, setData] as const;
};
