import Data from "../types/Data";

/**
 * If the group has no name and some people, name it after the first word of
 * each person's name.
 */
export default function autoNameGroup(data: Data, groupId: number) {
  let group = data.Group[groupId];

  let people = group.personIds.map((id) => data.Person[id]);

  let words = people
    .map((person) => person.name.split(/\s+/g)[0])
    .filter((word) => word.length > 0);

  let computedName = words.join(" ");

  return {
    ...data,
    Group: {
      ...data.Group,
      [groupId]: {
        ...group,
        computedName,
      },
    },
  };
}
