import addOrUpdateItem from "../items/addOrUpdateItem";
import getItemsByIds from "../items/getItemsByIds";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function nudgeReceiptItems(
  data: Data,
  receiptId: number,
  offset: -1 | 1,
): Data {
  const receipt = data.Receipt[receiptId];
  const items = getItemsByIds(data, receipt.itemIds);

  for (let i = 0; i < items.length; i++) {
    const dstItem = items[i];
    const srcItemPrice = items[i - offset]?.price;

    data = addOrUpdateItem(data, "update", {
      ...dstItem,
      price: srcItemPrice ?? 0,
    });
  }

  data = recalculateTotals(data);

  return data;
}
