import { css, cx, keyframes } from "@emotion/css";
import React, { useEffect, useState } from "react";
import Balancer from "react-wrap-balancer";
import useDeleteReceipt from "../actions/useDeleteReceipt";
import chain from "../data/function/chain";
import getAllGroupsAndPeople from "../data/groupsAndPeople/getAllGroupsAndPeople";
import getItemsByIds from "../data/items/getItemsByIds";
import toggleItemPersonOrGroup from "../data/items/toggleItemPersonOrGroup";
import updateItemName from "../data/items/updateItemName";
import updateItemPrice from "../data/items/updateItemPrice";
import addItemToReceipt from "../data/receipts/addItemToReceipt";
import nudgeReceiptItems from "../data/receipts/nudgeReceiptItems";
import receiptHasNoItems from "../data/receipts/receiptHasNoItems";
import updateReceiptAndItems from "../data/receipts/updateReceiptAndItems";
import updateReceiptName from "../data/receipts/updateReceiptName";
import updateReceiptTotal from "../data/receipts/updateReceiptTotal";
import Receipt from "../data/types/Receipt";
import { useDataStorage } from "../data/useDataStorage";
import getReceiptOcr from "../ocr/getReceiptOcr";
import { Cell } from "../visual/DesignSystem";
import Flex from "../visual/Flex";
import Hr from "../visual/Hr";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";
import Price from "../visual/Price";
import Section from "../visual/Section";
import TableCell from "../visual/TableCell";
import TableRow from "../visual/TableRow";
import TextButton, { GlyphAdd, GlyphTimes } from "../visual/TextButton";
import CellTextEditor from "./CellTextEditor";
import { GroupsAndPeopleSelectorTable } from "./GroupsAndPeopleSelectorTable";
import ItemRow from "./ItemRow";
import TipCalculator, {
  TipCalculatorState,
  calculateTipAndTotal,
} from "./TipCalculator";
import useToggleList from "./react/useToggleList";

const flashKeyframes = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const flash3Times = css`
  animation: ${flashKeyframes} 0.5s 3;
`;

const ReceiptTable: React.FC<{
  receipt: Receipt;
}> = ({ receipt }) => {
  const modal = useModalWindow();
  const storage = useDataStorage();
  const highlightErrors = storage.highlightErrors || undefined;
  const isEditing = storage.isEditing || undefined;
  const deleteReceipt = useDeleteReceipt();

  const [[uploadState, uploadError], setUploadStateAndError] = //
    useState<[number, string]>([0, ""]);
  const isUploading = uploadState > 0 && uploadState < 4;
  const [scannedSubtotal, setScannedSubtotal] = useState(0);

  const [expandedItemIds, setExpandedItemIds, setExpandedItemId] =
    useToggleList<number>();
  useEffect(() => {
    if (!isEditing) {
      setExpandedItemIds(new Set());
    }
  }, [isEditing]);

  const hasTotalError =
    storage.isEditing &&
    receipt.total > 0 &&
    receipt.total < receipt.computedSubtotal;
  if (hasTotalError) {
    console.error("hasTotalError", receipt.computedSubtotal, receipt.total);
  }

  const items = storage.getData(getItemsByIds, receipt.itemIds);

  const everyItemIsWholeDollar = items.every((item) => item.price % 100 === 0);
  const minDecimals = everyItemIsWholeDollar ? 0 : 2;

  return (
    <Section
      titleClassName={css`
        width: ${isEditing && !isUploading && "80%"};
      `}
      title={
        <CellTextEditor
          type="text"
          placeholder="Restaurant name"
          value={receipt.name}
          onChange={
            isEditing && !isUploading
              ? (name) => storage.setData(updateReceiptName, receipt.id, name)
              : undefined
          }
          autofocusIfEmpty
        >
          {isEditing
            ? isUploading
              ? "Scanning receipt..."
              : receipt.name
            : ["Receipt", receipt.name].filter(Boolean).join(" for ")}
        </CellTextEditor>
      }
    >
      {(uploadState === 4 ||
        storage.getData(receiptHasNoItems, receipt.id)) && (
        <TableRow>
          <TableCell
            key={uploadState}
            data-input
            className={css`
              display: block;
              width: 100%;
              text-align: center;
              position: relative;
              padding: 8rem;
              margin: 8rem;
              /* border: 2rem dotted green; */
              /* border-style: ${uploadState === 0 && "solid"}; */
              /* color: green; */
              border-radius: 8rem;
              ${uploadState === 4 ? flash3Times : undefined};
            `}
          >
            {uploadState === 0 ? (
              <>
                <input
                  type="file"
                  className={css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    width: 100%; // Firefox only
                    opacity: 0 !important;
                    cursor: pointer;
                  `}
                  accept="image/*"
                  onChange={async (e) => {
                    e.preventDefault();
                    const input = e.currentTarget;

                    const file = input.files?.[0];
                    if (!file) return;

                    const receiptScan = //
                      await getReceiptOcr(file, ([state, error]) => {
                        if (state === 4) return;
                        setUploadStateAndError([state, error]);
                      });
                    if (!receiptScan) return;
                    setScannedSubtotal(Math.round(receiptScan.subtotal * 100));

                    input.value = "";

                    storage.setData(
                      updateReceiptAndItems,
                      {
                        ...receipt,
                        name: receiptScan.restaurantOrStoreName ?? "",
                        total: receiptScan.totalPaid * 100,
                      },
                      receiptScan.items.map((rawItem) => ({
                        name: rawItem.name,
                        price: rawItem.price * 100,
                        personIds: [],
                      })),
                    );
                    if (receiptScan.warnings) {
                      await modal.alert(
                        [
                          "Scan complete! Please check for errors.",
                          "Specifically, our friendly AI reported these possible errors:",
                          "" + receiptScan.warnings,
                        ].join("\n\n"),
                      );
                    } else {
                      await modal.alert(
                        [
                          //
                          "Scan complete!",
                          "We tried our best, but most scans aren't perfect. Please check for errors.",
                        ].join("\n\n"),
                      );
                    }
                    setUploadStateAndError([4, ""]);
                  }}
                />
                + New! Tap to scan your receipt
              </>
            ) : uploadState === 1 ? (
              <>Resizing image...</>
            ) : uploadState === 2 ? (
              <>Extracting text from image...</>
            ) : uploadState === 3 ? (
              <>Parsing receipt text...</>
            ) : uploadState === 4 ? (
              <>
                <Balancer>Receipt scanned. How did we do?</Balancer>
                <br />
                <a
                  className="green"
                  onClick={() => setUploadStateAndError([0, ""])}
                >
                  [ Good enough! Keep this scan ]
                </a>
                <br />
                <a
                  onClick={async () => {
                    const shouldContinue = await modal.confirm(
                      "Tips for a better scan:\n\nMake sure the photo is not blurry.\n\nMake sure lines of text are aligned horizontally.\n\nFrame the photo to only include the relevant stuff: restaurant name, items, and total paid.",
                      {
                        danger: true,
                        okText: "Clear and retry!",
                      },
                    );
                    if (!shouldContinue) return;

                    setScannedSubtotal(0);
                    setUploadStateAndError([0, ""]);
                    storage.setData(updateReceiptAndItems, { ...receipt }, []);
                  }}
                >
                  <span
                    className={css`
                      color: red;
                      filter: saturate(0.6);
                    `}
                  >
                    [ Not great. Clear and retry ]
                  </span>
                </a>
                <br />
                <br />
                <Balancer>
                  You can manually add back any missing items. Tip: Press Enter
                  to insert items at any position.
                </Balancer>
              </>
            ) : (
              <>
                Sorry, an error occurred: {uploadError} <br />
                <br />
                <a onClick={() => setUploadStateAndError([0, ""])}>
                  Try again?
                </a>
              </>
            )}
          </TableCell>
        </TableRow>
      )}

      {isEditing && receipt.itemIds.length > 0 && (
        <TableRow>
          <TableCell>
            <TextButton
              onClick={async () => {
                storage.setData(addItemToReceipt, receipt.id, {}, 0);
              }}
            >
              <GlyphAdd />
            </TextButton>
          </TableCell>
          <Cell grow />
          {storage.isEditing &&
            uploadState === 4 &&
            receipt.computedSubtotal !== 0 && (
              <TableCell>
                <span>Re-align prices:</span>
                {
                  <TextButton
                    onClick={async () => {
                      if (
                        items[0]?.price === 0 ||
                        (await modal.confirm(
                          "The first item's price will be cleared, and all prices will be moved up one row.",
                          { okText: "Nudge up", danger: true },
                        ))
                      ) {
                        storage.setData(nudgeReceiptItems, receipt.id, -1);
                      }
                    }}
                  >
                    [▲]
                  </TextButton>
                }
                {
                  <TextButton
                    onClick={async () => {
                      if (
                        items[items.length - 1]?.price === 0 ||
                        (await modal.confirm(
                          "The last item's price will be cleared, and all prices will be moved down one row.",
                          { okText: "Nudge down", danger: true },
                        ))
                      ) {
                        storage.setData(nudgeReceiptItems, receipt.id, +1);
                      }
                    }}
                  >
                    [▼]
                  </TextButton>
                }
              </TableCell>
            )}
        </TableRow>
      )}

      {items.map((item, index) => {
        const needsToAddMoreItems = item.personIds.length === 0;

        return (
          <React.Fragment key={item.id}>
            <ItemRow
              item={item}
              minDecimals={minDecimals}
              onChangeName={
                isEditing &&
                ((name) => storage.setData(updateItemName, item.id, name))
              }
              onChangePrice={
                isEditing &&
                ((price) => storage.setData(updateItemPrice, item.id, price))
              }
              onKeyPressEnter={
                isEditing &&
                (() =>
                  storage.setData(addItemToReceipt, receipt.id, {}, index + 1))
              }
              secondRow={
                <GroupsAndPeopleSelectorTable
                  placeholder="+ Who got this item?"
                  label="Who got this item?"
                  isExpanded={expandedItemIds.has(item.id)}
                  onChangeExpanded={(expanded) =>
                    setExpandedItemId(item.id, expanded)
                  }
                  groupsAndPeople={storage.getData(getAllGroupsAndPeople)}
                  selectedPersonAndGroupIds={item.personIds}
                  onChangePersonOrGroupSelected={(personOrGroupId, selected) =>
                    storage.setData(
                      toggleItemPersonOrGroup,
                      item.id,
                      personOrGroupId,
                      selected,
                      modal,
                    )
                  }
                  error={(highlightErrors && needsToAddMoreItems) || undefined}
                />
              }
            />
          </React.Fragment>
        );
      })}

      {isEditing && (
        <>
          {!isUploading && (
            <TableRow>
              <TableCell>
                <TextButton
                  onClick={async () => {
                    storage.setData(addItemToReceipt, receipt.id, {});
                  }}
                  data-error={
                    (highlightErrors && receipt.itemIds.length <= 0) ||
                    undefined
                  }
                >
                  {receipt.itemIds.length <= 0 ? (
                    <>
                      <GlyphAdd /> Or input items manually
                    </>
                  ) : (
                    <GlyphAdd />
                  )}
                </TextButton>
              </TableCell>
              <Cell grow />
              {storage.isEditing && (
                <TableCell>
                  <TextButton onClick={() => deleteReceipt(receipt)}>
                    <GlyphTimes double />
                  </TextButton>
                </TableCell>
              )}
            </TableRow>
          )}
        </>
      )}

      {!isUploading && <Hr />}

      {!isUploading && (
        <ItemRow
          item={{
            name: "Subtotal",
            price: receipt.computedSubtotal,
          }}
          minDecimals={minDecimals}
        />
      )}

      {!isUploading &&
        scannedSubtotal > 0 &&
        (scannedSubtotal === receipt.computedSubtotal ? (
          <TableRow>
            <TableCell flex="1 0 0">
              <Flex
                flex="1 0 0"
                x="4rem center"
                y="4rem"
                className={cx(
                  "green",
                  css`
                    margin-top: -8rem;
                    text-align: center;
                  `,
                )}
              >
                <Balancer>✔ Matches subtotal from receipt scan!</Balancer>
              </Flex>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell flex="1 0 0">
              <Flex
                flex="1 0 0"
                x="4rem center"
                y="4rem"
                data-error
                className={css`
                  text-align: center;
                `}
              >
                <Balancer>
                  The scanned receipt looked like it said the subtotal was{" "}
                  {Price(scannedSubtotal)}, but the items here add up to{" "}
                  {Price(receipt.computedSubtotal)}. Verify all item prices are
                  correct.
                </Balancer>
              </Flex>
            </TableCell>
          </TableRow>
        ))}

      {storage.isEditing &&
        !isUploading &&
        receipt.computedSubtotal > 0 &&
        receipt.total === 0 && (
          <TableRow>
            <TableCell flex="1 0 0">
              <TextButton
                onClick={async () => {
                  // Make sure the user should use the tip calculator.
                  {
                    let response = await modal.confirm(
                      [
                        "Tip Calculator",
                        "If you haven't paid yet and you're trying to calculate how much to tip, use this tool.",
                        "First, double-check your receipt. Was tip already added?",
                      ].join("\n\n"),
                      {
                        cancelText: "Close",
                        okText: "Tip wasn't already added",
                      },
                    );
                    if (!response) return;
                  }

                  // Make sure the subtotal is correct.
                  {
                    let response = await modal.confirm(
                      [
                        "Tip Calculator",
                        "Does this match what's shown on your receipt?",
                        `Subtotal: ${Price(receipt.computedSubtotal)}`,
                        "If not, you probably entered some item prices incorrectly. Fix the prices before calculating tip.",
                      ].join("\n\n"),
                      {
                        cancelText: "Close",
                        okText: "Subtotal is correct ",
                      },
                    );
                    if (!response) return;
                  }

                  // Fill in the right numbers.
                  const state: TipCalculatorState = {
                    subtotalFromReceipt: receipt.computedSubtotal,
                    tipBase: receipt.computedSubtotal,
                    tipFraction: 0.15,
                    preTipTotal: 0,
                    tipBaseIsPreTipTotal: true,
                    computedTip: 0,
                    computedTotal: 0,
                    computedTotalRef: { current: 0 },
                  };
                  chain(
                    state,
                    calculateTipAndTotal,
                    storage.setTipCalculatorState,
                  );

                  // Show the tip calculator.
                  {
                    let response = await modal.confirm(TipCalculator, {
                      cancelText: "Cancel",
                      okText: "Use Total",
                    });
                    if (!response) return;
                  }

                  // If the user clicked "Use Total", update the receipt.
                  storage.setData(
                    updateReceiptTotal,
                    receipt.id,
                    state.computedTotalRef.current,
                  );

                  // Clear the tip calculator state.
                  storage.setTipCalculatorState(undefined);
                }}
                className={
                  receipt.total === 0 &&
                  storage
                    .getData(getItemsByIds, receipt.itemIds)
                    .every((item) => item.price > 0)
                    ? "green"
                    : undefined
                }
              >
                [Help me calculate the tip and total]
              </TextButton>
            </TableCell>
          </TableRow>
        )}

      {!isUploading && (
        <ItemRow
          item={{
            name: "Total",
            price: receipt.total,
          }}
          minDecimals={minDecimals}
          onChangePrice={
            isEditing &&
            ((price) => storage.setData(updateReceiptTotal, receipt.id, price))
          }
          priceError={hasTotalError}
        />
      )}

      {!isUploading && hasTotalError && (
        <TableRow>
          <TableCell flex="1 0 0">
            <Flex
              flex="1 0 0"
              x="4rem center"
              y="4rem"
              data-error
              className={css`
                text-align: center;
              `}
            >
              <Balancer>
                The total is less than the subtotal. Did you enter everything
                correctly?
              </Balancer>
            </Flex>
          </TableCell>
        </TableRow>
      )}
    </Section>
  );
};

export default ReceiptTable;
