/**
 * Chain together a series of functions that take a single argument and return
 * a single value. The return value of each function is passed as the argument
 * to the next function.
 *
 * This is the closest we can get to a pipe operator in TypeScript for now!
 *
 * Compare to: [pipe in fp-ts][1]
 *
 * [1]: https://gcanti.github.io/fp-ts/modules/function.ts.html#pipe
 */
export default function chain<A, B>(a: A, fn1: (a: A) => B): B;
export default function chain<A, B, C>(
  a: A,
  fn1: (a: A) => B,
  fn2: (b: B) => C,
): C;
export default function chain<A, B, C, D>(
  a: A,
  fn1: (a: A) => B,
  fn2: (b: B) => C,
  fn3: (c: C) => D,
): D;
export default function chain<A, B, C, D, E>(
  a: A,
  fn1: (a: A) => B,
  fn2: (b: B) => C,
  fn3: (c: C) => D,
  fn4: (d: D) => E,
): E;
export default function chain<A, B, C, D, E, F>(
  a: A,
  fn1: (a: A) => B,
  fn2: (b: B) => C,
  fn3: (c: C) => D,
  fn4: (d: D) => E,
  fn5: (e: E) => F,
): F;
export default function chain<T>(value: T, ...fns: ((arg: any) => any)[]) {
  return fns.reduce((value, fn) => fn(value), value);
}
