import { css, cx } from "@emotion/css";
import React, { useEffect, useRef, useState } from "react";
import { ResetUaStyles } from "../visual/ResetUaStyles";

const CellTextEditor: React.FC<{
  type: "text" | "number-hundredths";
  align?: "right";
  children?: string;
  placeholder?: string;
  value: string;
  error?: boolean;
  onChange?: (value: string) => void;
  onKeyPressEnter?: () => void;
  autofocusIfEmpty?: boolean;
  userSelectAll?: boolean;
}> = (props) => {
  const [editingValue, setEditingValue] = useState<string | undefined>(
    undefined,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  let displayValue = props.children ?? props.value;

  useEffect(() => {
    if (props.autofocusIfEmpty && props.value === "") {
      inputRef.current?.focus();
    }
  }, []);

  const CommonStyle = css`
    box-sizing: border-box;
    padding: 0 2rem;

    width: 100%;
    text-align: ${props.align};
    justify-content: ${props.align === "right" ? "flex-end" : "flex-start"};
  `;

  const SpanStyle =
    props.userSelectAll &&
    css`
      user-select: all;
      cursor: pointer;
    `;

  const InputStyle = css`
    ${ResetUaStyles};
    ${CommonStyle};

    display: inline-block;
    padding: 1rem 4rem;

    --webkit-touch-callout: none;
    user-select: none;

    position: relative;
    border-radius: 0.025rem;
  `;

  // Disabled because this causes the Cut/Copy/Paste menu to appear on mobile.
  // useEffect(() => {
  //   if (editingValue != null) {
  //     inputRef.current?.focus();
  //   }
  // }, [editingValue == null]);

  return props.onChange == null ? (
    <span className={cx(CommonStyle, SpanStyle)}>{displayValue}</span>
  ) : (
    <input
      className={InputStyle}
      ref={inputRef}
      autoComplete="false"
      type="text"
      inputMode={props.type === "number-hundredths" ? "decimal" : undefined}
      placeholder={props.placeholder}
      value={editingValue ?? displayValue}
      onChange={(e) => {
        setEditingValue(e.target.value);
      }}
      onFocus={(e) => {
        setEditingValue(props.value);
      }}
      onBlur={(e) => {
        let newValue = e.target.value;
        if (props.value !== newValue) {
          props.onChange?.(newValue);
        }
        setEditingValue(undefined);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          props.onKeyPressEnter?.();
        }
      }}
      data-error={props.error || undefined}
    />
  );
};

export default CellTextEditor;
