import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export function useNewBill() {
  const modal = useModalWindow();

  return async function newBill() {
    await new Promise((resolve) => setTimeout(resolve));
    if (!(await modal.confirm("Start a new bill from scratch?"))) return;

    location.href = "/";
  };
}
