import { HTMLAttributes } from "react";
import Flex from "./Flex";

const TableRow: React.FC<
  {
    as?: React.ComponentProps<typeof Flex>["as"];
    wrap?: React.ComponentProps<typeof Flex>["wrap"];
    flex?: React.ComponentProps<typeof Flex>["flex"];
  } & HTMLAttributes<HTMLElement>
> = (props) => {
  const { wrap, flex, ...rest } = props;

  return (
    <Flex
      as={props.as}
      flex={props.flex}
      x={wrap ? "0 end 16rem" : "0 stretch 16rem"}
      y="0 start 0"
      wrap={props.wrap}
      {...rest}
    />
  );
};

export default TableRow;
