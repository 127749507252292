import { css, cx } from "@emotion/css";
import { HTMLAttributes } from "react";
import { useDataStorage } from "../data/useDataStorage";
import Flex from "./Flex";

const TableCell: React.FC<
  {
    className?: string;
    children?: React.ReactNode;
    as?: React.ComponentProps<typeof Flex>["as"];
    flex?: React.ComponentProps<typeof Flex>["flex"];
  } & HTMLAttributes<HTMLElement>
> = (props) => {
  const storage = useDataStorage();
  const { className, children, as, flex, ...rest } = props;

  return (
    <Flex
      as={props.as}
      flex={props.flex}
      x="end"
      y="center"
      className={cx(
        css`
          box-sizing: border-box;
          min-height: ${storage.isEditing ? "20rem" : undefined};
          margin: ${storage.isEditing ? "1rem 0" : undefined};
        `,
        props.className,
      )}
      {...rest}
    >
      {props.children}
    </Flex>
  );
};

export default TableCell;
