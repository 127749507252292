import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import { Balancer } from "react-wrap-balancer";
import { useEdit } from "../actions/useEdit";
import { useNewBill } from "../actions/useNewBill";
import { useSave } from "../actions/useSave";
import { useShare } from "../actions/useShare";
import getVenmo from "../data/payment/getVenmo";
import { useDataStorage } from "../data/useDataStorage";
import couldBeComplete from "../data/validation/couldBeComplete";
import isEmpty from "../data/validation/isEmpty";
import { asyncQrCodeDataUrl } from "../sharing/qrcode";
import shareUrl from "../sharing/shareUrl";
import BorderedBox from "../visual/BorderedBox";
import Flex from "../visual/Flex";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";
import TextButton from "../visual/TextButton";
import { I } from "./I";
import Logo from "./Logo";

const Button: React.FC<{
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  children: React.ReactNode;
}> = (props) => {
  return (
    <TextButton
      className={css`
        visibility: ${props.onClick ? undefined : "hidden"};
      `}
      onClick={props.onClick}
    >
      {props.children}
    </TextButton>
  );
};

const Header: React.FC<unknown> = () => {
  const storage = useDataStorage();
  const share = useShare();
  const edit = useEdit();
  const save = useSave();
  const newBill = useNewBill();
  const modal = useModalWindow();

  const [qrCodeUrl, setQrCodeUrl] = useState<string | undefined>(undefined);
  useEffect(() => {
    async function effect() {
      if (storage.getData(getVenmo)) {
        let appUrl = //
          new URL(storage.getData(shareUrl, 2), location.href).toString();
        let qrCodeUrl = await asyncQrCodeDataUrl(appUrl);
        setQrCodeUrl(qrCodeUrl);
      } else {
        setQrCodeUrl(undefined);
      }
    }
    effect();
  }, [storage.data]);

  return (
    <BorderedBox>
      <Flex
        x="center"
        className={css`
          margin-top: -1.3em;
          align-self: stretch;
        `}
      >
        {storage.isEditing ? (
          <>
            <Flex flex="1 0 0" />
            <Button onClick={storage.getData(isEmpty) ? undefined : save}>
              [Save]
            </Button>
          </>
        ) : (
          <>
            <Button onClick={storage.getData(isEmpty) ? undefined : newBill}>
              [New]
            </Button>
            <Flex flex="1 0 0" />
            <Button
              onClick={storage.getData(couldBeComplete) ? share : undefined}
            >
              {storage.getData(getVenmo) ? (
                <>[Re-link Venmo]</>
              ) : (
                <>[Link Venmo]</>
              )}
            </Button>
            <Button onClick={edit}>[Edit]</Button>
          </>
        )}
      </Flex>

      {storage.isEditing ? (
        <>
          <Balancer>
            Welcome to <I>BillJoy</I>!
          </Balancer>

          <Balancer>
            Ever had dinner with friends and everyone ordered something
            different?
          </Balancer>

          <Balancer>
            Split your bill easily with BillJoy in just a minute or two.
          </Balancer>

          <Balancer>
            Find us on the web at <I>billjoy.app</I>.
          </Balancer>
        </>
      ) : (
        <>
          <div />
          <div />
          <Logo />
          <div />

          <Balancer>
            <I>BillJoy</I>
            <br />
            <>easy & fun bill splitting</>
          </Balancer>

          {storage.getData(getVenmo) && (
            <>
              {qrCodeUrl && (
                <Flex
                  y="0.5em stretch 0.75em"
                  x="center"
                  className={css`
                    text-align: center;
                  `}
                >
                  <div />
                  <div />
                  <Balancer>
                    <I>Is everyone still at the table?</I>
                    <br />
                    Have everyone scan this QR code:
                  </Balancer>
                  <Balancer>
                    <span
                      className={css`
                        max-width: 80%;
                        background: linear-gradient(312deg, #4caf50, #009688);
                        padding: 1rem;
                        display: inline-block;
                        line-height: 0;
                        border-radius: 8rem;
                        margin: 4rem;
                      `}
                    >
                      <img
                        src={qrCodeUrl}
                        className={css`
                          max-width: 100%;
                          border-radius: 7rem;
                          mix-blend-mode: lighten;
                        `}
                      />
                    </span>
                  </Balancer>
                  <Balancer>
                    <I>Did people leave already?</I> <br />
                    Press the share button in your browser and send this into
                    the group chat.
                    <br />
                    <a
                      onClick={async () => {
                        await modal.alert(
                          "Compatibility note about iMessage:\n\nSharing the page URL into iMessage might not work. Sorry about that!\n\nAs a workaround, please try this:\n\n1. Long-press the QR code.\n2. Share that image into iMessage.\n3. Tell everyone that they can long-press the QR code they receive to open the link.",
                        );
                      }}
                    >
                      [ If you use iMessage, tap here ]
                    </a>
                  </Balancer>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </BorderedBox>
  );
};

export default Header;
