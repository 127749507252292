import { createContext, useContext, useMemo, useState } from "react";
import { TipCalculatorState } from "../views/TipCalculator";

const UiStateContext = createContext<{
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  highlightErrors: boolean;
  setHighlightErrors: React.Dispatch<React.SetStateAction<boolean>>;
  tipCalculatorState: TipCalculatorState | undefined;
  setTipCalculatorState: React.Dispatch<
    React.SetStateAction<TipCalculatorState | undefined>
  >;
}>(undefined!);

export const UiStateProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [isEditing, setIsEditing] = useState(true);
  const [highlightErrors, setHighlightErrors] = useState(false);
  const [tipCalculatorState, setTipCalculatorState] = useState<
    TipCalculatorState | undefined
  >();

  const memoizedUiState = useMemo(
    () => ({
      isEditing,
      setIsEditing,
      highlightErrors,
      setHighlightErrors,
      tipCalculatorState,
      setTipCalculatorState,
    }),
    [
      isEditing,
      setIsEditing,
      highlightErrors,
      setHighlightErrors,
      tipCalculatorState,
      setTipCalculatorState,
    ],
  );

  return (
    <UiStateContext.Provider value={memoizedUiState}>
      {props.children}
    </UiStateContext.Provider>
  );
};

export const useUiState = () => {
  return useContext(UiStateContext);
};
