export default async function ocrImage(image: File) {
  const body = new FormData();
  body.append("file", image);
  const response = await fetch("/api/ocr", { method: "POST", body });
  if (!response.ok) {
    throw new Error("OCR API returned " + response.status);
  }

  const json = await response.json();
  const text = json.ParsedResults[0].ParsedText;
  console.debug(text);
  return text;
}
