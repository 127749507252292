import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function updateReceiptTotal(
  data: Data,
  receiptId: number,
  total: number,
): Data {
  return recalculateTotals({
    ...data,
    Receipt: {
      ...data.Receipt,
      [receiptId]: {
        ...data.Receipt[receiptId],
        total,
      },
    },
  });
}
