import { css } from "@emotion/css";
import Balancer from "react-wrap-balancer";
import { useDataStorage } from "../data/useDataStorage";
import looksIncomplete from "../data/validation/looksIncomplete";
import Flex from "../visual/Flex";

const ErrorsNotice: React.FC<unknown> = (props) => {
  const storage = useDataStorage();

  function showErrors() {
    storage.setHighlightErrors(true);
    storage.setIsEditing(true);
  }

  return (
    <>
      {!storage.isEditing && storage.getData(looksIncomplete) && (
        <Flex
          flex="1 0 0"
          x="4rem center"
          y="4rem"
          data-error
          className={css`
            text-align: center;
          `}
        >
          <Balancer>
            It looks like you haven't finished filling this out.{" "}
            <a onClick={showErrors}>[Check for errors]</a>
          </Balancer>
        </Flex>
      )}
    </>
  );
};

export default ErrorsNotice;
