import getPaymentDetails from "../data/payment/getPaymentDetails";
import Person from "../data/types/Person";
import { useDataStorage } from "../data/useDataStorage";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export default function usePay() {
  const storage = useDataStorage();
  const modal = useModalWindow();

  return async function pay(person: Person, price: number) {
    const { venmo, venmoPayUrl, venmoRequestUrl, amount } = storage.getData(
      getPaymentDetails,
      price,
    );

    const payMessage = [
      `Hi ${person.name},`,
      `Please pay $${amount} to @${venmo} on Venmo.`,
      `Thanks!`,
      `Note: There is currently a bug in Facebook Messenger's in-app browser on iOS. If the button below doesn't work, long-press it and select Open Link. Thanks Irene for reporting 🫶`,
    ];

    // const requestMessage = [
    //   storage.isEditing &&
    //     "Send your friend this bill. When they tap this Pay button, here is what they'll see:",
    //   ...payMessage,
    //   storage.isEditing &&
    //     "Alternatively, instead of sending them the bill, you can request from each person.",
    //   storage.isEditing &&
    //     `Tap "Request" to request from ${person.name} on Venmo now. (Only works on mobile!)`,
    // ];

    const requestMessage = [
      `${person.name}'s share of the bill is $${amount}.`,
      `You can tap the button below to directly send them a Venmo request.`,
      `(Only works on mobile!)`,
      `Easier alternative: Close this and tap [Link Venmo] to get a QR code you can share with everyone.`,
    ];

    if (!venmo) {
      const result = await modal.confirm(
        requestMessage.filter(Boolean).join("\n\n"),
        {
          okText: `Request $${amount}`,
          cancelText: "Close",
        },
      );
      if (result) {
        window.open(venmoRequestUrl);
      }
    } else {
      await modal.confirm(payMessage.filter(Boolean).join("\n\n"), {
        okText:
          Math.random() < 0.15 ? "Let me do it for you" : `Pay $${amount}`,
        okUrl: venmoPayUrl,
        cancelText: "Later",
      });
    }
  };
}
