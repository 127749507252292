import Data from "../types/Data";
import Group from "../types/Group";

export function getHardcodedGroups(data: Data) {
  // A group is a shortcut for a set of people.
  // We currently only support a single hard-coded group: "everyone".
  return {
    0: {
      id: 0,
      computedName: "",
      name: "Everyone",
      initials: "all",
      personIds: data.personIds,
      computedTotal: NaN,
    },
  } as Record<number, Group>;
}
