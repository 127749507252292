import Data from "../types/Data";
import Person from "../types/Person";

export default function addOrUpdatePerson(
  data: Data,
  mode: "add",
  newPerson: Person,
  position?: number,
): Data;
export default function addOrUpdatePerson(
  data: Data,
  mode: "update",
  partialPerson: Partial<Person> & { id: Person["id"] },
): Data;
export default function addOrUpdatePerson(
  data: Data,
  mode: "add" | "update",
  personOrPartialPerson: Person | (Partial<Person> & { id: Person["id"] }),
  position?: number,
): Data {
  const person = {
    ...data.Person[personOrPartialPerson.id],
    ...personOrPartialPerson,
  };
  let personIds = data.personIds;
  if (mode === "add") {
    personIds = [...data.personIds];
    if (position == null) {
      position = personIds.length;
    }
    personIds.splice(position, 0, person.id);
  } else {
    personIds = data.personIds;
  }

  return {
    ...data,
    personIds,
    Person: {
      ...data.Person,
      [person.id]: person,
    },
    maxPersonId: mode === "add" ? person.id : data.maxPersonId,
  };
}
