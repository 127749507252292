import { createContext, useContext, useMemo, useState } from "react";
import ModalWindowProps from "../visual/ModalWindow/ModalWindowProps";

type SetModalProps = React.Dispatch<React.SetStateAction<ModalWindowProps>>;

const ModalStateContext = createContext<[ModalWindowProps, SetModalProps]>(
  undefined!,
);

export const ModalStateProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const modalState = useState<ModalWindowProps>({ type: undefined });

  const memoizedModalState = useMemo(() => modalState, modalState);

  return (
    <ModalStateContext.Provider value={memoizedModalState}>
      {props.children}
    </ModalStateContext.Provider>
  );
};

export const useModalState = () => {
  return useContext(ModalStateContext);
};
