import Data from "../types/Data";
import Group from "../types/Group";

export default function addOrUpdateGroup(
  data: Data,
  mode: "add",
  newGroup: Group,
  position?: number,
): Data;
export default function addOrUpdateGroup(
  data: Data,
  mode: "update",
  partialGroup: Partial<Group> & { id: Group["id"] },
): Data;
export default function addOrUpdateGroup(
  data: Data,
  mode: "add" | "update",
  groupOrPartialGroup: Group | (Partial<Group> & { id: Group["id"] }),
  position?: number,
): Data {
  const group = {
    ...data.Group[groupOrPartialGroup.id],
    ...groupOrPartialGroup,
  };
  let groupIds = data.groupIds;
  if (mode === "add") {
    groupIds = [...data.groupIds];
    if (position == null) {
      position = groupIds.length;
    }
    groupIds.splice(position, 0, group.id);
  } else {
    groupIds = data.groupIds;
  }

  return {
    ...data,
    groupIds,
    Group: {
      ...data.Group,
      [group.id]: group,
    },
    maxGroupId: mode === "add" ? group.id : data.maxGroupId,
  };
}
