import chain from "../function/chain";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function deleteEmptyRecords(data: Data): Data {
  // There is no delete button anywhere, so this will have to do for now.
  // We delete a record when it contains no user-entered data.
  let personIds = [...data.personIds];
  let groupIds = [...data.groupIds];
  let receiptIds = [...data.receiptIds];
  let Person = { ...data.Person };
  let Group = { ...data.Group };
  let Receipt = { ...data.Receipt };
  let Item = { ...data.Item };

  // An item is empty if it has no name and no people.
  // Also, keep track of which people have assigned items.
  let personOrGroupIdsWithItems = new Set<number>();
  for (let itemId in Item) {
    let item = Item[itemId];
    if (item.name === "" && item.personIds.length === 0) {
      delete Item[itemId];
    }
    for (let personId of item.personIds) {
      personOrGroupIdsWithItems.add(personId);
    }
  }

  // Delete item ids for deleted items.
  for (let receiptId in Receipt) {
    let receipt = Receipt[receiptId];
    Receipt[receiptId] = {
      ...receipt,
      itemIds: receipt.itemIds.filter((itemId) => Item[itemId]),
    };
  }

  // A receipt is empty if it has no name, no items, and no total.
  // Note that we must run this after deleting empty items.
  for (let receiptId in Receipt) {
    let receipt = Receipt[receiptId];
    if (
      receipt.name === "" &&
      receipt.itemIds.length === 0 &&
      receipt.total === 0
    ) {
      delete Receipt[receiptId];
    }
  }

  // Delete receipt ids for deleted receipts.
  receiptIds = receiptIds.filter((receiptId) => Receipt[receiptId]);

  // A person is empty if they have no name and if no items are assigned to
  // them.
  for (let personId in Person) {
    let person = Person[personId];
    if (person.name === "" && !personOrGroupIdsWithItems.has(+personId)) {
      delete Person[personId];
    }
  }

  // Delete person ids for deleted people.
  personIds = personIds.filter((personId) => Person[personId]);

  // A group is empty if it has no name, no people, and no items assigned to
  // it.
  for (let groupId in Group) {
    let group = Group[groupId];
    if (
      group.name === "" &&
      group.personIds.length === 0 &&
      !personOrGroupIdsWithItems.has(+groupId)
    ) {
      delete Group[groupId];
    }
  }

  // Delete group ids for deleted groups.
  groupIds = groupIds.filter((groupId) => Group[groupId]);

  let newData = chain(
    {
      ...data,
      personIds,
      groupIds,
      receiptIds,
      Person,
      Group,
      Receipt,
      Item,
    },
    recalculateTotals,
  );

  return newData;
}
