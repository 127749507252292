import { css } from "@emotion/css";
import React from "react";
import Flex from "../visual/Flex";
import CellTextEditor from "./CellTextEditor";

const CellPercentageEditor: React.FC<{
  percentage: number;
  error?: boolean;
  onChange?: (value: number) => void;
}> = (props) => {
  let displayValue = (props.percentage * 100).toFixed(1);

  return (
    <Flex
      x="0 0.5ch"
      y="center"
      className={css`
        width: 7ch;
      `}
    >
      <CellTextEditor
        type="number-hundredths"
        placeholder={displayValue}
        value=""
        align="right"
        error={props.error}
        onChange={
          props.onChange &&
          ((valueAsString) => {
            if (!props.onChange) return;

            const valueAsNumber = parseFloat(valueAsString);
            if (isNaN(valueAsNumber)) return;
            props.onChange(valueAsNumber / 100);
          })
        }
      >
        {props.percentage === 0 ? undefined : displayValue}
      </CellTextEditor>
      <span>%</span>
    </Flex>
  );
};
export default CellPercentageEditor;
