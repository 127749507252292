import chain from "../function/chain";
import recalculateInitials from "../recalculateInitials";
import Data from "../types/Data";
import Group from "../types/Group";
import addOrUpdateGroup from "./addOrUpdateGroup";

export default function addGroup(
  data: Data,
  group: Partial<Group>,
  position?: number,
): Data {
  const id = data.maxGroupId - 1;
  const newGroup: Group = {
    id,
    name: "",
    initials: "",
    personIds: [],
    computedTotal: 0,
    computedName: "",
    ...group,
  };

  return chain(
    data,
    (data) => addOrUpdateGroup(data, "add", newGroup, position),
    recalculateInitials,
  );
}
