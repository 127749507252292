import Data from "../types/Data";

export default function looksIncomplete(data: Data) {
  // Is there a receipt?
  if (data.receiptIds.length === 0) {
    return true;
  }

  for (let receiptId of data.receiptIds) {
    // Do any receipts have empty totals?
    let receipt = data.Receipt[receiptId];
    if (receipt.total === 0) {
      return true;
    }

    // Do any receipts have no items?
    if (receipt.itemIds.length === 0) {
      return true;
    }

    for (let itemId of receipt.itemIds) {
      // Do any items have no price?
      let item = data.Item[itemId];
      if (item.price === 0) {
        return true;
      }

      // Do any items have no people?
      if (data.personIds.length >= 2 && item.personIds.length === 0) {
        return true;
      }
    }

    for (let groupId of data.groupIds) {
      // Do any groups have no people?
      let group = data.Group[groupId];
      if (group.personIds.length === 0) {
        return true;
      }
    }
  }
  return false;
}
