import chain from "../function/chain";
import Data from "../types/Data";
import addOrUpdateItem from "./addOrUpdateItem";

export default function updateItemName(
  data: Data,
  itemId: number,
  name: string,
): Data {
  const newPartialItem = {
    id: itemId,
    name: name.trim(),
  };

  return chain(data, (data) => addOrUpdateItem(data, "update", newPartialItem));
}
