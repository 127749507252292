import { useState } from "react";

export default function useToggleList<T>(initialItems: T[] | Set<T> = []) {
  const [items, setItems] = useState(() => new Set<T>(initialItems));

  function toggleItem(item: T, value: boolean) {
    setItems((expandedItemIds) => {
      let newExpandedItemIds = new Set(expandedItemIds);
      value ? newExpandedItemIds.add(item) : newExpandedItemIds.delete(item);
      return newExpandedItemIds;
    });
  }

  return [items, setItems, toggleItem] as const;
}
