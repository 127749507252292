import chain from "../function/chain";
import recalculateInitials from "../recalculateInitials";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";
import Person from "../types/Person";
import addOrUpdatePerson from "./addOrUpdatePerson";

export default function addPerson(
  data: Data,
  person: Partial<Person>,
  position?: number,
): Data {
  const id = data.maxPersonId + 1;
  const newPerson: Person = {
    id,
    name: "",
    initials: "",
    computedTotal: 0,
    ...person,
  };

  return chain(
    data,
    (data) => addOrUpdatePerson(data, "add", newPerson, position),
    recalculateInitials,
    recalculateTotals,
  );
}
