import createUrl from "../../views/url/createUrl";
import getReceipts from "../receipts/getReceipts";
import Data from "../types/Data";
import getVenmo from "./getVenmo";

export default function getPaymentDetails(data: Data, price: number) {
  let venmo = getVenmo(data);
  let amount = (price / 100).toFixed(2);
  let names = getReceipts(data)
    .map((receipt) => receipt.name)
    .join(" & ");

  let payNote = names + " 💸 billjoy.app";
  let venmoPayUrl = createUrl("https://venmo.com/" + venmo, {
    txn: "pay",
    amount,
    note: payNote.replace(/ /g, "\u00A0"),
  });

  // Venmo doesn't allow URLs in requests.
  let requestNote = names + " 💸 billjoy dot app";
  let venmoRequestUrl = createUrl("https://venmo.com/", {
    txn: "request",
    amount,
    note: requestNote.replace(/ /g, "\u00A0"),
  });

  return { venmo, venmoPayUrl, venmoRequestUrl, amount };
}
