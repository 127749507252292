import chain from "../function/chain";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function deleteItem(data: Data, itemId: number): Data {
  if (!data.Item[itemId]) {
    return data;
  }

  let Item = { ...data.Item };
  delete Item[itemId];

  let Receipt = { ...data.Receipt };
  for (let receiptId in Receipt) {
    let receipt = Receipt[receiptId];
    if (receipt.itemIds.includes(itemId)) {
      Receipt[receiptId] = {
        ...receipt,
        itemIds: receipt.itemIds.filter((id) => id !== itemId),
      };
    }
  }

  return chain(
    {
      ...data,
      Item,
      Receipt,
    },
    recalculateTotals,
  );
}
