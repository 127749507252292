import chain from "../function/chain";
import autoNameGroup from "../groups/autoNameGroup";
import recalculateInitials from "../recalculateInitials";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function deleteGroupOrPerson(
  data: Data,
  groupOrPersonId: number,
): Data {
  if (groupOrPersonId > 0) {
    if (!data.Person[groupOrPersonId]) {
      return data;
    }

    let personIds = data.personIds.filter((id) => id !== groupOrPersonId);

    let Person = { ...data.Person };
    delete Person[groupOrPersonId];

    for (let groupId in data.Group) {
      let Group = { ...data.Group };
      let group = Group[groupId];
      if (group.personIds.includes(groupOrPersonId)) {
        Group[groupId] = {
          ...group,
          personIds: group.personIds.filter((id) => id !== groupOrPersonId),
        };
        data = {
          ...data,
          Group,
        };
        data = autoNameGroup(data, group.id);
      }
    }

    data = {
      ...data,
      personIds,
      Person,
    };
  } else {
    if (!data.Group[groupOrPersonId]) {
      return data;
    }

    let groupIds = data.groupIds.filter((id) => id !== groupOrPersonId);

    let Group = { ...data.Group };
    delete Group[groupOrPersonId];

    data = {
      ...data,
      groupIds,
      Group,
    };
  }

  let Item = { ...data.Item };
  for (let itemId in Item) {
    let item = Item[itemId];
    if (item.personIds.includes(groupOrPersonId)) {
      Item[itemId] = {
        ...item,
        personIds: item.personIds.filter((id) => id !== groupOrPersonId),
      };
    }
  }

  return chain(
    {
      ...data,
      Item,
    },
    recalculateTotals,
    recalculateInitials,
  );
}
