import { css, cx } from "@emotion/css";
import { Balancer } from "react-wrap-balancer";
import Flex from "../Flex";

const ModalWindowButton: React.FC<{
  variant?: "primary" | "danger-primary";
  url?: string;
  onClick?: () => void;
  children?: string;
}> = (props) => {
  return (
    <a
      className={css`
        display: contents;
        color: unset;
      `}
      onClick={props.onClick}
      href={props.url}
      target="_blank"
    >
      <Flex
        x
        className={css`
          cursor: pointer;
          user-select: none;
          padding: 10rem;
          margin: -10rem;

          & > * {
            filter: saturate(0.6);
          }

          &[disabled] > * {
            filter: saturate(0.05);
            opacity: 0.5;
          }

          &:not([disabled]):active > * {
            filter: invert(1) hue-rotate(180deg);
            border-color: white;
          }

          box-sizing: border-box;
          width: 150rem;
        `}
      >
        <Flex
          flex="1 0 0"
          x="4rem center"
          y="center"
          className={cx(
            css`
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 20rem;
              background: white;

              font-weight: bold;
              text-align: center;
              margin: 0 auto;
              white-space: pre-wrap;
            `,
            props.children === "Let me do it for you" &&
              css`
                overflow: hidden;
                position: relative;
                text-shadow: 0 0 6px black;
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: url("https://i1.sndcdn.com/artworks-6ibwybgttgXxkEvL-HnIymQ-t500x500.jpg")
                    center / cover;
                  opacity: 0.55;
                  z-index: -1;
                }
              `,
            props.variant === "primary"
              ? css`
                  background-color: black;
                  color: white;
                `
              : props.variant === "danger-primary"
                ? css`
                    background-color: red;
                    color: white;
                  `
                : undefined,
          )}
        >
          {/* Without this div, "Open Venmo" gets wrapped for some reason. */}
          <div>
            <Balancer>{props.children}</Balancer>
          </div>
        </Flex>
      </Flex>
    </a>
  );
};

export default ModalWindowButton;
