import chain from "../function/chain";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";
import addOrUpdateItem from "./addOrUpdateItem";

export default function updateItemPrice(
  data: Data,
  itemId: number,
  price: number,
): Data {
  const newPartialItem = {
    id: itemId,
    price,
  };

  return chain(
    data,
    (data) => addOrUpdateItem(data, "update", newPartialItem),
    recalculateTotals,
  );
}
