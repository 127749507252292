import { useDataStorage } from "../data/useDataStorage";
import couldBeComplete from "../data/validation/couldBeComplete";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export function useEdit() {
  const modal = useModalWindow();
  const storage = useDataStorage();

  return async function edit() {
    await new Promise((resolve) => setTimeout(resolve));
    if (storage.getData(couldBeComplete)) {
      await modal.alert(
        [
          "In BillJoy, you can only edit your own copy of bills.",
          "If you already shared the bill and want your party to see the edits, you can share the edited copy with them.",
        ].join("\n"),
        {
          okText: "Edit My Copy",
        },
      );
    }

    storage.setIsEditing(true);
  };
}
