import Data from "../types/Data";
import everyReceiptHasNonZeroTotal from "./everyReceiptHasNonZeroTotal";
import lastReceiptHasNOrMoreItems from "./lastReceiptHasNOrMoreItems";

export default function couldBeComplete(data: Data) {
  return (
    data.personIds.length >= 2 &&
    data.receiptIds.length >= 1 &&
    everyReceiptHasNonZeroTotal(data) &&
    lastReceiptHasNOrMoreItems(data, 1)
  );
}
