import getAllGroupsById from "./groups/getAllGroupsById";
import Data from "./types/Data";

/**
 * Recalculate all computed values without mutating data.
 */
export default function recalculateTotals(data: Data) {
  let Group = getAllGroupsById(data);

  let Person = { ...data.Person };
  for (let person of Object.values(Person)) {
    Person[person.id] = {
      ...person,
      computedTotal: 0,
    };
  }

  let Receipt = { ...data.Receipt };
  for (let receipt of Object.values(Receipt)) {
    receipt = Receipt[receipt.id] = {
      ...receipt,
      computedSubtotal: 0,
    };

    // Add up all the item prices to the subtotal.
    for (let itemId of receipt.itemIds) {
      let item = data.Item[itemId];
      receipt.computedSubtotal += item.price;
    }

    let ratio =
      receipt.computedSubtotal === 0
        ? 1
        : receipt.total / receipt.computedSubtotal;

    // Add up all the item prices to each person's total.
    for (let itemId of receipt.itemIds) {
      let item = data.Item[itemId];

      let personIdsIncludingGroups = [];
      for (let personOrGroupId of item.personIds) {
        if (personOrGroupId > 0) {
          personIdsIncludingGroups.push(personOrGroupId);
        } else {
          let group = Group[personOrGroupId];
          for (let personId of group.personIds) {
            personIdsIncludingGroups.push(personId);
          }
        }
      }
      for (let personId of personIdsIncludingGroups) {
        let person = Person[personId];
        person.computedTotal +=
          (item.price * ratio) / personIdsIncludingGroups.length;
      }
    }

    // Round to the nearest cent.
    for (let person of Object.values(Person)) {
      person.computedTotal = Math.round(person.computedTotal);
    }

    // Round to the nearest cent.
    for (let receipt of Object.values(Receipt)) {
      receipt.computedSubtotal = Math.round(receipt.computedSubtotal);
    }
  }

  return {
    ...data,
    Person,
    Receipt,
  };
}
