import { css } from "@emotion/css";

export const ResetUaStyles = css`
  box-sizing: border-box;
  width: 100%;
  font: unset;
  margin: unset;
  padding: unset;
  border: unset;
  text-align: unset;
`;
