import Group from "../types/Group";
import Person from "../types/Person";

export default function getDisplayName(groupOrPerson: Group | Person): string {
  let name = groupOrPerson.name;
  if (groupOrPerson.id <= 0 && name === "") {
    name = (groupOrPerson as Group).computedName;
  }

  if (groupOrPerson.initials == null || groupOrPerson.id === 0) {
    return name;
  } else if (name.startsWith("[" + groupOrPerson.initials + "]")) {
    return name;
  } else {
    return "[" + groupOrPerson.initials + "] " + name;
  }
}
