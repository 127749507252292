import { z } from "zod";
import { GroupSchema } from "./Group";
import { ItemSchema } from "./Item";
import { PersonSchema } from "./Person";
import { ReceiptSchema } from "./Receipt";

export const DataSchema = z.object({
  venmo: z.string(),
  personIds: z.array(z.number()),
  groupIds: z.array(z.number()).default([]), // [1]
  receiptIds: z.array(z.number()),

  Person: z.record(z.string(), PersonSchema),
  maxPersonId: z.number(),
  Group: z.record(z.string(), GroupSchema).default({}), // [1]
  maxGroupId: z.number().default(0), // [1]
  Receipt: z.record(z.string(), ReceiptSchema),
  maxReceiptId: z.number(),
  Item: z.record(z.string(), ItemSchema),
  maxItemId: z.number(),
});

// [1]: Support for groups added 2023-07-25 in 7df3178.

type Data = z.infer<typeof DataSchema>;

export default Data;
