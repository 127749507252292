import addOrUpdateItem from "../items/addOrUpdateItem";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";
import Item from "../types/Item";
import Receipt from "../types/Receipt";

export default function updateReceiptAndItems(
  data: Data,
  receipt: Pick<Receipt, "id"> & Partial<Receipt>,
  items: Omit<Item, "id">[],
): Data {
  data = {
    ...data,
    Receipt: {
      ...data.Receipt,
      [receipt.id]: {
        ...data.Receipt[receipt.id],
        ...receipt,
        itemIds: [],
      },
    },
  };

  for (const item of items) {
    const id = data.maxItemId + 1;
    data = addOrUpdateItem(data, "add", { ...item, id }, receipt.id);
  }

  data = recalculateTotals(data);

  return data;
}
