import addGroup from "../data/groups/addGroup";
import hasUserGroups from "../data/groups/hasUserGroups";
import { useDataStorage } from "../data/useDataStorage";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export function useAddGroup() {
  const modal = useModalWindow();
  const storage = useDataStorage();

  return async function addGroupFlow(position?: number) {
    if (!storage.getData(hasUserGroups)) {
      let response = await modal.confirm(
        [
          "Groups is an advanced feature that can help in situations like this:",
          "Ali, Ben, Cat, and Dan get food together. Ali, Ben, and Cat get some items to split, but Dan gets his own items.",
          "In this case, it can be helpful to create a group for Ali, Ben, and Cat so that you don't have to keep selecting all three of them for each item they shared.",
          "Does this sound like what you need?",
        ].join("\n\n"),
        {
          okText: "Yes, use groups",
          cancelText: "Not now",
        },
      );

      if (!response) return;
    }

    storage.setData(addGroup, {}, position);
  };
}
