import React, { Fragment, useEffect } from "react";
import getUserGroups from "../data/groups/getUserGroups";
import hasUserGroups from "../data/groups/hasUserGroups";
import toggleGroupPerson from "../data/groups/toggleGroupPerson";
import updateGroupName from "../data/groups/updateGroupName";
import getPeople from "../data/people/getPeople";
import { useDataStorage } from "../data/useDataStorage";
import { Row } from "../visual/DesignSystem";
import TextButton, { GlyphAdd } from "../visual/TextButton";
import GroupOrPersonRow from "./GroupOrPersonRow";
import { GroupsAndPeopleSelectorTable } from "./GroupsAndPeopleSelectorTable";
import useToggleList from "./react/useToggleList";
import { useAddGroup } from "./useAddGroup";

const GroupsTable: React.FC<unknown> = () => {
  const storage = useDataStorage();
  const highlightErrors = storage.highlightErrors || undefined;
  const isEditing = storage.isEditing || undefined;
  const addGroup = useAddGroup();

  const [expandedGroupIds, setExpandedGroupIds, setExpandedGroupId] =
    useToggleList<number>();
  useEffect(() => {
    if (!isEditing) {
      setExpandedGroupIds(new Set());
    }
  }, [isEditing]);

  return (
    <>
      {isEditing && storage.getData(hasUserGroups) && (
        <Row>
          <TextButton onClick={() => addGroup(0)}>
            <GlyphAdd />
          </TextButton>
        </Row>
      )}

      {storage.getData(getUserGroups).map((group, index) => {
        const isExpanded = expandedGroupIds.has(group.id);
        return (
          <Fragment key={group.id}>
            <GroupOrPersonRow
              style="total"
              placeholder="Group"
              personOrGroup={group}
              onChangeName={
                isEditing &&
                ((name) => {
                  storage.setData(updateGroupName, group.id, name);
                })
              }
              onKeyPressEnter={isEditing && (() => addGroup(index + 1))}
              price={group.computedTotal}
              secondRow={
                <GroupsAndPeopleSelectorTable
                  placeholder="+ Add group members"
                  label="People in this group:"
                  isExpanded={isExpanded}
                  onChangeExpanded={
                    isEditing &&
                    (() => {
                      setExpandedGroupId(group.id, !isExpanded);
                      storage.setData(updateGroupName, group.id, undefined);
                    })
                  }
                  groupsAndPeople={storage.getData(getPeople)}
                  selectedPersonAndGroupIds={group.personIds}
                  onChangePersonOrGroupSelected={(personOrGroupId, selected) =>
                    storage.setData(
                      toggleGroupPerson,
                      group.id,
                      personOrGroupId,
                      selected,
                    )
                  }
                  error={
                    (highlightErrors && group.personIds.length <= 0) ||
                    undefined
                  }
                />
              }
            />
          </Fragment>
        );
      })}

      {isEditing &&
        (storage.getData(hasUserGroups) ||
          storage.getData(getPeople).length >= 3) && (
          <Row>
            <TextButton onClick={() => addGroup()}>
              {storage.getData(hasUserGroups) ? (
                <GlyphAdd />
              ) : (
                <>
                  <GlyphAdd /> Add a group of guests (advanced)
                </>
              )}
            </TextButton>
          </Row>
        )}
    </>
  );
};

export default GroupsTable;
