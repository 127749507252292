import chain from "../function/chain";
import recalculateInitials from "../recalculateInitials";
import Data from "../types/Data";
import addOrUpdatePerson from "./addOrUpdatePerson";

export default function updatePersonName(
  data: Data,
  personId: number,
  name: string,
) {
  const newPartialPerson =
    name == null
      ? {
          id: personId,
        }
      : {
          id: personId,
          name: name.trim(),
        };

  return chain(
    data,
    (data) => addOrUpdatePerson(data, "update", newPartialPerson),
    recalculateInitials,
  );
}
