const debugPrompt = `
Start with a bullet list of explanations of your choices for things that could be ambiguous, then output the JSON as a Markdown code block.
`;

const prompt = `I have a receipt OCR result.

Please turn the useful part of this receipt into this exact format:

{
  restaurantOrStoreName?: string,
  items: {name?: string, price?: number}[]
  subtotal: number;
  totalPaid: number;
  warnings: string | undefined;
}

Notes about 'restaurantOrStoreName':
- It is usually the first or first few lines.
- If there is an address, it is above it and not below it.
- If you see something slightly repeated, you are seeing a OCR'd store logo and then the name in plaintext. Ignore other heuristics and select this as the store name. If the two occurrences are slightly different, use the second occurrence because it's more likely to be scanned correctly.

Notes about 'items':
- Output items exactly in the same order in the source text.
- If there are any typos that are very likely a result of a bad scan, please spell-correct them. You can take advantage of the fact that receipts often have the same item multiple times.
- If there is any garbled text that appears to be a badly OCR'd item, please insert a placeholder or blank item.
- If there is a line without a price, it is probably part of the previous item. Merge it with the previous item.
- Do not do any math or make up numbers. Only use prices that exist literally in the scan.

Notes about 'warnings':
- Use this field to output any general errors or places where the parsing may be inaccurate.
- This field will be directly shown to the user, so use human-readable terms and don't reference the JSON key names.
- If there are no warnings, output an empty string here. Do not output the string "No warnings".

Here is the OCR text:

`;

export interface ParsedReceiptResponse {
  restaurantOrStoreName: string;
  items: { name: string; price: number }[];
  subtotal: number;
  totalPaid: number;
  warnings: string;
}

function is(item: any, types: string[]) {
  let result;
  if (types.includes("undefined") && item == null) {
    result = true;
  } else {
    result = types.includes(typeof item);
  }

  if (!result) {
    console.error(item, types);
    // eslint-disable-next-line no-debugger
    debugger;
  }
  return result;
}

export default async function parseReceiptText(
  text: string,
): Promise<ParsedReceiptResponse> {
  const response = await fetch("/api/complete", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      model: "gpt-3.5-turbo-1106",
      response_format: { type: "json_object" },
      messages: [{ role: "user", content: prompt + text }],
      temperature: 0.7,
    }),
  });
  if (!response.ok) {
    throw new Error("Parsing API returned " + response.status);
  }

  const json = await response.json();
  if (json?.error?.message) {
    throw new Error("Parsing API: " + json?.error?.message);
  }

  const aiResponse = json.choices[0].message.content;
  console.log(aiResponse);
  const aiJson = JSON.parse(aiResponse);

  return {
    restaurantOrStoreName: aiJson?.restaurantOrStoreName?.toString() ?? "",
    items: aiJson?.items?.length
      ? aiJson.items.map((rawItem: any) => ({
          name: rawItem?.name ?? "",
          price: rawItem?.price ?? 0,
        }))
      : [],
    subtotal: aiJson?.subtotal ?? 0,
    totalPaid: aiJson?.totalPaid ?? 0,
    // Despite our best attempts, the AI will still often output "No warnings".
    warnings: aiJson?.warnings === "No warnings" ? "" : aiJson?.warnings ?? "",
  };
}
