import chain from "../function/chain";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function deleteReceipt(data: Data, receiptId: number): Data {
  if (!data.Receipt[receiptId]) {
    return data;
  }

  let receiptIds = data.receiptIds.filter((id) => id !== receiptId);

  let Receipt = { ...data.Receipt };
  delete Receipt[receiptId];

  return chain(
    {
      ...data,
      Receipt,
      receiptIds,
    },
    recalculateTotals,
  );
}
