import { css, cx } from "@emotion/css";
import { MouseEventHandler } from "react";
import Flex from "./Flex";

export const GlyphTimes: React.FC<{
  double?: boolean;
}> = (props) => {
  return (
    <span
      className={css`
        font-size: 1.3em;
        line-height: 1em;
      `}
    >
      &times;
      {props.double && (
        <span
          className={css`
            margin-left: -0.2em;
          `}
        >
          &times;
        </span>
      )}
    </span>
  );
};

export const GlyphAdd: React.FC = () => {
  return (
    <span
      className={css`
        font-size: 1.2em;
        line-height: 1em;
      `}
    >
      +
    </span>
  );
};

const TextButton: React.FC<{
  "className"?: string;
  "onClick"?: MouseEventHandler<HTMLAnchorElement>;
  "children": React.ReactNode;
  "selected"?: boolean;
  "data-error"?: true;
}> = (props) => {
  return (
    <Flex
      as="a"
      inline
      x="0 1ch"
      y="center"
      onClick={props.onClick}
      data-error={props["data-error"]}
      className={cx(
        css`
          padding: 0rem 6rem;
        `,
        props.className,
      )}
    >
      {props.selected ? <i>{props.children}</i> : props.children}
    </Flex>
  );
};

export default TextButton;
