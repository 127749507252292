import Data from "../data/types/Data";
import shareUrl from "./shareUrl";

export default function updateLocationToShareUrl(data: Data | undefined) {
  let version: 1 | 2 = location.hostname === "v1.billjoy.app" ? 1 : 2;
  let newPath = shareUrl(data, version);

  if (newPath !== location.pathname) {
    window.history.pushState({}, "", newPath);
  }
  return newPath;
}
