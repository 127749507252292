import { css, cx } from "@emotion/css";
import { HTMLAttributes } from "react";

const Section: React.FC<
  {
    titleClassName?: string;
    title?: React.ReactNode;
    children: React.ReactNode;
  } & Omit<HTMLAttributes<HTMLElement>, "title">
> = (props) => {
  const { titleClassName, title, children, ...rest } = props;

  return (
    <fieldset
      className={css`
        border: 0 solid black;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4rem;

        &:empty {
          display: none;
        }

        // Can't use :has(> legend) because Firefox doesn't support it yet
        border-top-width: ${props.title ? "1.5px" : undefined};
      `}
      {...rest}
    >
      {props.title && (
        <legend
          className={cx(
            css`
              align-self: center; // Needed for Safari only
              text-align: center;
              padding: 0 8rem;

              // The margin-x auto is needed for Firefox only
              margin: 0 auto 4rem;
            `,
            props.titleClassName,
          )}
        >
          {props.title}
        </legend>
      )}
      {props.children}
    </fieldset>
  );
};

export default Section;
