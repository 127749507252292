export default function countFrequency(arrayOrString: string | string[]) {
  let array =
    typeof arrayOrString === "string"
      ? Array.from(arrayOrString)
      : arrayOrString;
  let counts: Record<string, number> = {};
  for (let item of array) {
    if (counts[item] == null) {
      counts[item] = 0;
    }
    counts[item]++;
  }
  return counts;
}
