import { css, cx } from "@emotion/css";
import React from "react";
import useDeleteGroupOrPerson from "../actions/useDeleteGroupOrPerson";
import usePay from "../actions/usePay";
import getDisplayName from "../data/groupsAndPeople/getDisplayName";
import getVenmo from "../data/payment/getVenmo";
import Group from "../data/types/Group";
import Person from "../data/types/Person";
import { useDataStorage } from "../data/useDataStorage";
import Checkbox from "../visual/Checkbox";
import TableCell from "../visual/TableCell";
import TableRow from "../visual/TableRow";
import TextButton, { GlyphTimes } from "../visual/TextButton";
import CellPriceEditor from "./CellPriceEditor";
import CellTextEditor from "./CellTextEditor";

const SelectorStyle = css`
  -webkit-tap-highlight-color: transparent;
  border: 1.5rem dotted transparent;
  padding: 0rem 6rem;
  gap: 4rem;

  &:last-of-type {
    margin-bottom: 20rem;
  }

  &[data-checked] {
    border-color: black;
    color: #000000;
  }
  &[data-checked] + &[data-checked] {
    border-top-color: transparent;
  }
`;

const GroupOrPersonRow: React.FC<{
  placeholder: string;
  style: "total" | "selector";
  personOrGroup: Person | Group;
  price: number;
  checked?: boolean;
  onChangeName?: (name: string) => void;
  onKeyPressEnter?: () => void;
  onChangeChecked?: (checked: boolean) => void;
  secondRow?: React.ReactNode;
}> = (props) => {
  const storage = useDataStorage();
  const pay = usePay();
  const deleteGroupOrPerson = useDeleteGroupOrPerson();

  let computedDisplayName = undefined;
  if (
    !props.personOrGroup.name &&
    props.personOrGroup.id <= 0 &&
    (props.personOrGroup as Group).computedName
  ) {
    computedDisplayName = getDisplayName(props.personOrGroup);
  }

  return (
    <TableRow
      as={props.style === "selector" ? "a" : undefined}
      className={cx(props.style === "selector" && SelectorStyle)}
      // If we use onClick, tapping two adjacent people in quick succession will
      // cause the event to register as a double-tap on the first person and
      // will toggle the first person twice.
      onPointerUp={() => {
        props.onChangeChecked?.(!props.checked);
      }}
      data-checked={props.checked ? "" : undefined}
    >
      <TableRow flex="1 0 0" wrap>
        <TableRow
          flex={storage.isEditing ? "1 0 100%" : "1 0 0"}
          className={css`
            gap: 6rem;
          `}
        >
          {props.style === "selector" && (
            <TableCell>
              <Checkbox checked={props.checked} />
            </TableCell>
          )}
          <TableCell flex="1 0 0">
            <CellTextEditor
              type="text"
              placeholder={computedDisplayName ?? props.placeholder}
              value={props.personOrGroup.name}
              onChange={props.onChangeName}
              onKeyPressEnter={props.onKeyPressEnter}
              autofocusIfEmpty
            >
              {props.personOrGroup.name
                ? getDisplayName(props.personOrGroup)
                : props.style === "selector" || !storage.isEditing
                  ? props.personOrGroup.id > 0
                    ? "(Unnamed guest)"
                    : computedDisplayName ?? "(Unnamed group)"
                  : undefined}
            </CellTextEditor>
          </TableCell>
        </TableRow>
        {props.secondRow}
      </TableRow>

      {props.style === "total" && (
        <>
          {props.personOrGroup.computedTotal !== 0 && (
            <TableCell>
              <CellPriceEditor price={props.price} minDecimals={2} />
            </TableCell>
          )}
          {storage.isEditing && (
            <TableCell>
              <TextButton
                onClick={() => deleteGroupOrPerson(props.personOrGroup)}
              >
                <GlyphTimes />
              </TextButton>
            </TableCell>
          )}
        </>
      )}

      {props.style === "total" &&
        props.personOrGroup.computedTotal > 0 &&
        props.personOrGroup.id > 0 &&
        !storage.isEditing && (
          <TableCell>
            <a
              onClick={() => pay(props.personOrGroup as Person, props.price)}
              className="green"
            >
              {storage.getData(getVenmo) ? <>[Pay]</> : <>[Request]</>}
            </a>
          </TableCell>
        )}
    </TableRow>
  );
};

export default GroupOrPersonRow;
