import chain from "../function/chain";
import addOrUpdateItem from "../items/addOrUpdateItem";
import Data from "../types/Data";
import Item from "../types/Item";

export default function addItemToReceipt(
  data: Data,
  receiptId: number,
  receiptItem: Partial<Item>,
  position?: number,
): Data {
  const id = data.maxItemId + 1;
  const newItem: Item = {
    id,
    name: "",
    price: 0,
    personIds: [],
    ...receiptItem,
  };

  return chain(data, (data) =>
    addOrUpdateItem(data, "add", newItem, receiptId, position),
  );
}
