import deleteItem from "../data/items/deleteItem";
import Item from "../data/types/Item";
import { useDataStorage } from "../data/useDataStorage";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";
import Price from "../visual/Price";

export default function useDeleteItem() {
  const modal = useModalWindow();
  const storage = useDataStorage();

  return async function deleteItemFlow(item: Item) {
    const result = await modal.confirm(
      [
        //
        "Delete this item?",
        item.name,
        Price(item.price),
        `Tip: To insert a new item anywhere, select a name and press Enter.`,
      ].join("\n\n"),
      {
        danger: true,
        okText: "Delete",
      },
    );

    if (result) {
      storage.setData(deleteItem, item.id);
    }
  };
}
