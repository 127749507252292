/**
 * Returns a new array with the item added or removed.
 */
export default function setInArray(
  array: number[],
  item: number,
  value: boolean | undefined,
) {
  let index = array.indexOf(item);
  if (value === undefined) {
    value = index === -1;
  }

  if (value) {
    return index === -1 ? [...array, item] : array;
  } else {
    return array.filter((i) => i !== item);
  }
}
