import { css, cx } from "@emotion/css";
import React from "react";
import useDeleteItem from "../actions/useDeleteItem";
import Item from "../data/types/Item";
import { useDataStorage } from "../data/useDataStorage";
import Flex from "../visual/Flex";
import TableCell from "../visual/TableCell";
import TableRow from "../visual/TableRow";
import TextButton, { GlyphTimes } from "../visual/TextButton";
import CellPriceEditor from "./CellPriceEditor";
import CellTextEditor from "./CellTextEditor";

const ItemRow: React.FC<{
  item:
    | Item
    | {
        id?: undefined;
        name: Item["name"];
        price: Item["price"];
      };
  minDecimals: 0 | 2;
  onChangeName?: (name: string) => void;
  onChangePrice?: (price: number) => void;
  onKeyPressEnter?: () => void;
  secondRow?: React.ReactNode;
  priceError?: boolean;
}> = (props) => {
  const storage = useDataStorage();
  const deleteItem = useDeleteItem();

  const item = props.item;

  return (
    <TableRow>
      <TableRow
        flex="1 0 0"
        wrap
        className={cx(
          !storage.isEditing &&
            css`
              display: block;

              & > *:nth-child(1) {
                float: left;
              }

              & > *:nth-child(2) {
                float: right;
              }
            `,
        )}
      >
        <TableCell flex={storage.isEditing ? "1 0 100%" : "1 0 0"}>
          <CellTextEditor
            type="text"
            placeholder="Item"
            value={props.item.name}
            onChange={props.onChangeName}
            onKeyPressEnter={props.onKeyPressEnter}
            autofocusIfEmpty
          />
        </TableCell>
        {props.secondRow}
      </TableRow>
      <Flex x="0 2rem">
        <TableCell>
          <CellPriceEditor
            minDecimals={props.minDecimals}
            price={props.item.price}
            error={
              props.priceError ||
              (props.item.price <= 0 && storage.highlightErrors)
            }
            onChange={props.onChangePrice}
          />
        </TableCell>
        {storage.isEditing && item.id != null && (
          <TableCell>
            <TextButton onClick={() => deleteItem(item)}>
              <GlyphTimes />
            </TextButton>
          </TableCell>
        )}
      </Flex>
    </TableRow>
  );
};

export default ItemRow;
