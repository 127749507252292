import Data from "./types/Data";

const DataBlank: Data = {
  venmo: "",
  personIds: [],
  groupIds: [],
  receiptIds: [],

  Person: {},
  maxPersonId: 0,
  Group: {},
  maxGroupId: 0,
  Receipt: {},
  maxReceiptId: 0,
  Item: {},
  maxItemId: 0,
};

export default DataBlank;
