import React from "react";
import { Balancer } from "react-wrap-balancer";
import { useNewBill } from "../actions/useNewBill";
import { useDataStorage } from "../data/useDataStorage";
import isEmpty from "../data/validation/isEmpty";
import BorderedBox from "../visual/BorderedBox";
import TextButton from "../visual/TextButton";
import { I } from "./I";
import Logo from "./Logo";

const Footer: React.FC<unknown> = () => {
  const storage = useDataStorage();
  const newBill = useNewBill();

  return storage.getData(isEmpty) ? (
    <BorderedBox>
      <div />
      <Logo />
      <div />
      <Balancer>Happy bill splitting!</Balancer>
      <div />
      <Balancer>
        Follow us!{" "}
        <a
          target="_blank"
          rel="noopener"
          href="https://instagram.com/billjoy.app"
        >
          @billjoy.app
        </a>
      </Balancer>
      <div />
    </BorderedBox>
  ) : (
    <BorderedBox>
      {storage.isEditing ? (
        <>
          <Balancer>
            <I>Done filling this out?</I>
            <br />
            Scroll up, tap <I>[Save]</I>, and then tap <I>[Link Venmo]</I>.
          </Balancer>

          <Balancer>
            <I>Need to finish this later?</I>
            <br />
            Scroll up and tap <I>[Save]</I>. Then you can bookmark the page URL
            or send it to another device to continue working there.
          </Balancer>
        </>
      ) : (
        <>
          <Balancer>
            <I>Thanks for using BillJoy!</I>
          </Balancer>

          <Balancer>
            BillJoy is a web app that lets you easily split complex bills.
          </Balancer>

          <Balancer>
            Split your own bill the next time you go out. Just visit{" "}
            <TextButton onClick={newBill}>billjoy.app</TextButton> on your
            phone.
          </Balancer>
        </>
      )}
      <Balancer>
        <I>Have an idea? Found a bug?</I>
        <br />
        We &lt;3 your feedback!
        <br />
        DM{" "}
        <a
          target="_blank"
          rel="noopener"
          href="https://instagram.com/billjoy.app"
        >
          @billjoy.app
        </a>{" "}
        on Instagram.
      </Balancer>
    </BorderedBox>
  );
};

export default Footer;
