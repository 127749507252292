import styled from "@emotion/styled";

export const Row = styled.div<{
  gap?: string;
}>`
  display: flex;
  flex-flow: row nowrap;
  gap: ${(props) => props.gap ?? "16rem"};
`;

export const Cell = styled.div<{
  grow?: boolean;
  width?: string;
  align?: string;
}>`
  ${(props) => props.grow && "flex-basis: 0;"}
  ${(props) => props.grow && "flex-grow: 1;"}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.align && `text-align: ${props.align};`}
`;
