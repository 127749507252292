import { css } from "@emotion/css";
import Flex from "../Flex";

const Dialog: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  return (
    <Flex
      y="24rem 16rem"
      x="32rem"
      className={css`
        box-sizing: border-box;
        max-width: min(500rem, 97vw);

        background: white;
        border-radius: 3rem;
      `}
    >
      {props.children}
    </Flex>
  );
};

export default Dialog;
