// https://stackoverflow.com/a/30106551/782045
export function unicodeToB64(string: string) {
  let urlEncoded = encodeURIComponent(string);
  let bytes = urlEncoded.replace(/%([0-9A-F]{2})/g, (_, ord) =>
    String.fromCharCode(parseInt(ord, 16)),
  );
  return btoa(bytes);
}

// https://stackoverflow.com/a/30106551/782045
export function b64ToUnicode(str: string) {
  let urlEncoded = Array.from(atob(str))
    .map((chr) => "%" + ("00" + chr.charCodeAt(0).toString(16)).slice(-2))
    .join("");
  return decodeURIComponent(urlEncoded);
}
