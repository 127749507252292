import { useEffect } from "react";

/**
 * Await-friendly version of setTimeout.
 */
function delay(ms = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * If the up/down arrows are pressed, go to the previous/next text input.
 */
export default function useTextInputKeyListener() {
  useEffect(() => {
    async function handler(event: KeyboardEvent) {
      // We must be in a text input.
      if (!(event.target instanceof Element)) return;
      if (!event.target.matches("input[type=text]")) return;

      // The key pressed must but the up or down arrow.
      if (event.key !== "ArrowUp" && event.key !== "ArrowDown") return;

      const input = event.target as HTMLInputElement;
      const length = input.value.length;
      const cursorIsAtStart =
        input.selectionStart === 0 && input.selectionEnd === 0;
      const cursorIsAtEnd =
        input.selectionEnd === length && input.selectionStart === length;
      const entireInputIsSelected =
        input.selectionStart === 0 && input.selectionEnd === length;

      if (event.key === "ArrowUp") {
        if (!cursorIsAtStart && !entireInputIsSelected) return;

        event.preventDefault();
        const allInputs = [...document.querySelectorAll("input[type=text]")];
        const i = allInputs.indexOf(input);
        const inputToFocus = allInputs[i - 1] as HTMLInputElement;

        // Check if we're out of bounds:
        if (inputToFocus instanceof HTMLInputElement) {
          inputToFocus.focus();
          await delay(0);
          inputToFocus.select();
        }
      } else if (event.key === "ArrowDown") {
        if (!cursorIsAtEnd && !entireInputIsSelected) return;

        event.preventDefault();
        const allInputs = [...document.querySelectorAll("input[type=text]")];
        const i = allInputs.indexOf(input);
        const inputToFocus = allInputs[i + 1] as HTMLInputElement;

        // Check if we're out of bounds:
        if (inputToFocus instanceof HTMLInputElement) {
          inputToFocus.focus();
          await delay(0);
          inputToFocus.select();
        }
      }
    }

    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);
}
