import setInArray from "../arrays/setInArray";
import chain from "../function/chain";
import recalculateInitials from "../recalculateInitials";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";
import addOrUpdateGroup from "./addOrUpdateGroup";
import autoNameGroup from "./autoNameGroup";

export default function toggleGroupPerson(
  data: Data,
  groupId: number,
  personId: number,
  value: boolean | undefined = undefined,
) {
  const newPartialGroup = {
    id: groupId,
    personIds: setInArray(data.Group[groupId].personIds, personId, value),
  };

  return chain(
    data,
    (data) => addOrUpdateGroup(data, "update", newPartialGroup),
    (data) => autoNameGroup(data, groupId),
    recalculateInitials,
    recalculateTotals,
  );
}
