import getAllGroupsById from "../groups/getAllGroupsById";
import Data from "../types/Data";

export default function getPeopleAndGroupsByIds(data: Data, ids: number[]) {
  let Group = getAllGroupsById(data);
  let people = [];
  let groups = [];
  for (let id of ids) {
    if (id > 0) {
      if (data.Person[id]) {
        people.push(data.Person[id]);
      } else {
        throw new Error("Person id doesn't exist: " + id);
      }
    } else {
      if (Group[id]) {
        groups.push(Group[id]);
      } else {
        throw new Error("Group id doesn't exist: " + id);
      }
    }
  }
  return { people, groups };
}
