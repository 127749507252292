import { z } from "zod";

export const PersonSchema = z.object({
  id: z.number(),
  name: z.string(),
  initials: z.string(),
  computedTotal: z.number(),

  // This allows us to access computedName on a Person | Group union.
  computedName: z.undefined().optional(),
});

type Person = z.infer<typeof PersonSchema>;

export default Person;
