import Data from "../types/Data";

export default function updateReceiptName(
  data: Data,
  receiptId: number,
  name: string,
): Data {
  return {
    ...data,
    Receipt: {
      ...data.Receipt,
      [receiptId]: {
        ...data.Receipt[receiptId],
        name: name.trim(),
      },
    },
  };
}
