import getVenmo from "../data/payment/getVenmo";
import setVenmo from "../data/payment/setVenmo";
import { useDataStorage } from "../data/useDataStorage";
import updateLocationToShareUrl from "../sharing/updateLocationToShareUrl";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export function useShare() {
  const modal = useModalWindow();
  const storage = useDataStorage();

  return async function share() {
    await new Promise((resolve) => setTimeout(resolve));

    let path = undefined;
    let askForVenmo = true;

    if (false && !storage.getData(getVenmo)) {
      let response = await modal.confirm(
        "Do you want your friends to 1-tap pay you back via Venmo?\n\nYou'll need just your Venmo username, no login required!",
        {
          okText: "Yes",
          cancelText: "No",
        },
      );
      if (!response) {
        askForVenmo = false;
      }
    }

    let confirmedVenmo: string | undefined = undefined;
    if (askForVenmo) {
      while (confirmedVenmo == null) {
        let venmo = await modal.prompt(
          "Enter the exact Venmo handle of the person who paid this bill.\n\nThis is the only info we need. We won't ask you to log in to Venmo.",
          storage.getData(getVenmo),
        );
        if (venmo == null) return;

        if (venmo === storage.getData(getVenmo)) {
          break;
        } else if (venmo === "") {
          confirmedVenmo = "";
        } else {
          venmo = venmo.replace(/@/g, "");
          await modal.alert(
            "Let's make sure you typed that correctly.\n\nWe'll try opening the Venmo profile you just entered.\n\nWait until the profile loads, then come back here.",
            {
              okText: "Open Venmo",
            },
          );

          window.open("https://venmo.com/" + venmo);

          const response2 = await modal.confirm(
            "Did you see the Venmo profile of the person who paid the bill?",
            {
              okText: "Yes",
              cancelText: "No",
            },
          );
          if (response2) {
            confirmedVenmo = venmo;
          }
        }
      }

      if (confirmedVenmo != null) {
        path = updateLocationToShareUrl(
          await storage.setData(setVenmo, confirmedVenmo),
        );
        // await modal.alert("URL updated.");
      }
    }

    let newVenmo = confirmedVenmo ?? storage.getData(getVenmo);
    if (newVenmo) {
      await modal.alert(
        "Ready to share!\n\nYou can share this page via QR code or by pressing the share button in your browser.",
        {
          okText: "Show QR code",
        },
      );
    }

    // let url = path == null ? location.href : new URL(path, location.href).href;
    // if ("share" in navigator) {
    //   await modal.confirm("How do you want to share this bill?", {
    //     okText: "Share Panel",
    //     cancelText: "QR Code",
    //   });

    //   await modal.alert(
    //     "Note:\n\nSharing via iMessage doesn't work yet.\n\nSharing via copying link only works in some apps.\n\nTry sharing via AirDrop or Facebook Messenger! These are confirmed to work well.",
    //   );
    //   await navigator.share({ url });
    // }
  };
}
