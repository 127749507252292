// Use as a function:
function Price(price: number): string;

// Use as a component:
function Price(props: { children: number } | number): JSX.Element;

function Price(props: { children: number } | number) {
  if (typeof props === "number") {
    return `$${(props / 100).toFixed(2)}`;
  } else if (typeof props.children === "number") {
    return <>{Price(props.children)}</>;
  } else {
    return null;
  }
}

export default Price;
