import Data from "../data/types/Data";
import { serializeDataV1 } from "./serializationV1";
import { serializeDataV2 } from "./serializationV2";

export default function shareUrl(data: Data | undefined, version: 1 | 2) {
  if (data == null) {
    return "/";
  } else if (version === 1) {
    return "/s/" + "v1_" + serializeDataV1(data);
  } else {
    return "/s/" + "v2_" + serializeDataV2(data);
  }
}
