import { css } from "@emotion/css";
import React, { useEffect } from "react";
import Balancer from "react-wrap-balancer";
import hasUserGroups from "../data/groups/hasUserGroups";
import getVenmo from "../data/payment/getVenmo";
import getPeople from "../data/people/getPeople";
import hasPeople from "../data/people/hasPeople";
import hasReceipts from "../data/receipts/hasReceipts";
import { DataSchema } from "../data/types/Data";
import { useDataStorage } from "../data/useDataStorage";
import reset from "../data/validation/reset";
import useTextInputKeyListener from "../keys/useTextInputKeyListener";
import getDataFromUrl from "../sharing/getDataFromUrl";
import Flex from "../visual/Flex";
import Hr from "../visual/Hr";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";
import Section from "../visual/Section";
import ErrorsNotice from "./ErrorsNotice";
import Footer from "./Footer";
import GroupsTable from "./GroupsTable";
import Header from "./Header";
import { I } from "./I";
import PeopleTable from "./PeopleTable";
import ReceiptsTable from "./ReceiptsTable";

/**
 * There appears to be a iOS Safari CSS bug where the rem size is not calculated
 * accurately initially. This hook forces a recalculation of the rem size, which
 * seems to fix the bug.
 */
export function useFixSafariCssBug() {
  useEffect(() => {
    const root = document.querySelector("html");
    if (root) {
      const fontSize = getComputedStyle(root).getPropertyValue("font-size");
      root.style.fontSize = fontSize;
      setTimeout(() => {
        root.style.fontSize = "";
      }, 100);
    }
  }, []);
}

function useRefreshOnHistoryPop() {
  useEffect(() => {
    window.addEventListener("popstate", () => {
      location.reload();
    });
  }, []);
}

function getIsBday() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const isWithinOneDayOfNovember8 =
    currentMonth === 10 && currentDay >= 7 && currentDay <= 9;

  return isWithinOneDayOfNovember8;
}

const App: React.FC<unknown> = () => {
  const storage = useDataStorage();
  const modal = useModalWindow();

  useTextInputKeyListener();

  useEffect(() => {
    const rawData = getDataFromUrl(new URL(location.href));
    if (rawData) {
      const parseResults = DataSchema.safeParse(rawData);
      if (parseResults.success) {
        storage.setIsEditing(false);
        storage.setData(reset, parseResults.data);
      } else {
        console.error("URL data parse errors:", parseResults.error.errors);
        alert(
          [
            "Failed to load data from this BillJoy URL.",
            "If this is unexpected and you need help recovering data, please message @billjoy.app on Instagram and send us the page URL.",
            "Sorry for the inconvenience!",
          ].join("\n\n"),
        );
      }
    }
  }, []);

  useFixSafariCssBug();

  useRefreshOnHistoryPop();

  return (
    <>
      {getIsBday() && (
        <Flex y x="center">
          <a
            target="_blank"
            href="https://en.wikipedia.org/wiki/Bill_Joy"
            onClick={async (e) => {
              e.preventDefault();
              const a = e.currentTarget;

              if (
                await modal.alert(
                  "Bill Joy is the author of the vi text editor. He is not affiliated with this app (and probably doesn't know about it).",
                  {
                    okText: "Happy Birthday!",
                  },
                )
              ) {
                window.open(a.href, a.target);
              }
            }}
          >
            🎂 Happy birthday, Bill Joy! 🎂
          </a>
        </Flex>
      )}
      <Header />
      <ErrorsNotice />
      {!storage.isEditing && storage.getData(getVenmo) && (
        <Flex
          as="i"
          y="0.5em stretch 1.25em"
          x="0.5em center"
          className={css`
            text-align: center;
          `}
        >
          <Balancer>
            Your friend covered this bill. Venmo{" "}
            <I>
              @
              <span
                className={css`
                  user-select: all;
                  cursor: pointer;
                `}
              >
                {storage.getData(getVenmo)}
              </span>
            </I>{" "}
            back in about 10 seconds using the [Pay] link below.
          </Balancer>
        </Flex>
      )}
      <Section
        id="people-table"
        title={
          (storage.getData(hasPeople) ||
            (storage.isEditing && storage.getData(hasReceipts))) &&
          (storage.isEditing ? (
            <>People</>
          ) : (
            <>Your Party of {storage.getData(getPeople).length}</>
          ))
        }
      >
        {!storage.isEditing && storage.getData(getVenmo) && (
          <Flex
            as="i"
            y="0.5em stretch 1.25em"
            x="0.5em center"
            className={css`
              text-align: center;
            `}
          >
            <Balancer className="green">
              Find your name below and tap [Pay].
            </Balancer>
          </Flex>
        )}
        <PeopleTable />
        {!storage.isEditing && storage.getData(hasUserGroups) && <Hr />}
        {!storage.isEditing && <GroupsTable />}
      </Section>
      {storage.isEditing && (
        <Section title={storage.getData(hasUserGroups) && <>Groups</>}>
          <GroupsTable />
        </Section>
      )}
      <ReceiptsTable />
      <Footer />
      {modal.node}
    </>
  );
};

export default App;
