import { css } from "@emotion/css";
import Balancer from "react-wrap-balancer";
import chain from "../data/function/chain";
import { useDataStorage } from "../data/useDataStorage";
import BorderedBox from "../visual/BorderedBox";
import Flex from "../visual/Flex";
import Hr from "../visual/Hr";
import Price from "../visual/Price";
import Section from "../visual/Section";
import TableCell from "../visual/TableCell";
import TableRow from "../visual/TableRow";
import TextButton from "../visual/TextButton";
import { useFixSafariCssBug } from "./App";
import CellPercentageEditor from "./CellPercentageEditor";
import CellPriceEditor from "./CellPriceEditor";

export interface TipCalculatorState {
  subtotalFromReceipt: number;
  tipBase: number;
  tipBaseIsPreTipTotal: boolean;
  tipFraction: number;
  preTipTotal: number;
  computedTip: number;
  computedTotal: number;
  computedTotalRef: { current: number };
}

export function calculateTipAndTotal(
  state: TipCalculatorState,
): TipCalculatorState {
  const tipBase = state.tipBaseIsPreTipTotal
    ? state.preTipTotal
    : state.tipBase;

  const computedTip = tipBase * state.tipFraction;
  const computedTotal =
    state.preTipTotal === 0 ? 0 : state.preTipTotal + computedTip;
  state.computedTotalRef.current = computedTotal;

  return {
    ...state,
    tipBase,
    computedTip,
    computedTotal,
  };
}

const TipCalculator: React.FC<unknown> = () => {
  const storage = useDataStorage();
  const { tipCalculatorState: state, setTipCalculatorState: setState } =
    storage;

  useFixSafariCssBug();

  if (!state) {
    return null;
  }
  const stateNotNull = state;

  function setStateAndCalculate(newState: Partial<TipCalculatorState>) {
    chain({ ...stateNotNull, ...newState }, calculateTipAndTotal, setState);
  }

  const hasPreTipTotalError =
    state.preTipTotal > 0 && state.preTipTotal < state.subtotalFromReceipt;

  return (
    <BorderedBox as="div">
      <Section
        className={css`
          box-sizing: border-box;
          width: min(300rem, 95%);
        `}
      >
        <Flex x="center">Tip Calculator</Flex>

        <br />

        <Flex y>
          <TableRow>
            <TableCell flex="0 0 8rem" />
            <TableCell>Tipping based on</TableCell>
            <TableCell flex="1 0 0" />
            <TableCell>
              <CellPriceEditor
                muted={state.tipBaseIsPreTipTotal}
                price={state.tipBase}
                minDecimals={2}
                onChange={(newValue) =>
                  chain(
                    {
                      ...state,
                      tipBase: newValue,
                      tipBaseIsPreTipTotal: false,
                    },
                    calculateTipAndTotal,
                    setState,
                  )
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <Flex x flex="1 0 0">
              <TableCell flex="1 0 0" />
              <TableCell>
                <TextButton
                  onClick={() =>
                    setStateAndCalculate({ tipBaseIsPreTipTotal: true })
                  }
                  selected={state.tipBaseIsPreTipTotal}
                >
                  [total before tip]
                </TextButton>
              </TableCell>
              <TableCell>
                <TextButton
                  onClick={() =>
                    setStateAndCalculate({
                      tipBase: state.subtotalFromReceipt,
                      tipBaseIsPreTipTotal: false,
                    })
                  }
                  selected={
                    !state.tipBaseIsPreTipTotal &&
                    state.subtotalFromReceipt === state.tipBase
                  }
                >
                  [subtotal]
                </TextButton>
              </TableCell>
            </Flex>
          </TableRow>
        </Flex>

        <Flex y>
          <TableRow>
            <TableCell flex="0 0 8rem">&times;</TableCell>
            <TableCell>Tip percent</TableCell>
            <TableCell flex="1 0 0" />
            <TableCell>
              <CellPercentageEditor
                percentage={state.tipFraction}
                onChange={(newValue) =>
                  chain(
                    { ...state, tipFraction: newValue },
                    calculateTipAndTotal,
                    setState,
                  )
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <Flex x flex="1 0 0">
              <TableCell flex="1 0 0" />
              <TableCell>
                <TextButton
                  onClick={() => setStateAndCalculate({ tipFraction: 0.1 })}
                  selected={state.tipFraction === 0.1}
                >
                  10%
                </TextButton>
              </TableCell>
              <TableCell>
                <TextButton
                  onClick={() => setStateAndCalculate({ tipFraction: 0.15 })}
                  selected={state.tipFraction === 0.15}
                >
                  15%
                </TextButton>
              </TableCell>
              <TableCell>
                <TextButton
                  onClick={() => setStateAndCalculate({ tipFraction: 0.18 })}
                  selected={state.tipFraction === 0.18}
                >
                  18%
                </TextButton>
              </TableCell>
              <TableCell>
                <TextButton
                  onClick={() => setStateAndCalculate({ tipFraction: 0.2 })}
                  selected={state.tipFraction === 0.2}
                >
                  20%
                </TextButton>
              </TableCell>
              <TableCell>
                <TextButton
                  onClick={() => setStateAndCalculate({ tipFraction: 0.25 })}
                  selected={state.tipFraction === 0.25}
                >
                  25%
                </TextButton>
              </TableCell>
            </Flex>
          </TableRow>
        </Flex>

        <Hr />

        <TableRow>
          <TableCell flex="0 0 8rem" />
          <TableCell>Tip</TableCell>
          <TableCell flex="1 0 0" />
          <TableCell>
            <CellPriceEditor price={state.computedTip} minDecimals={2} />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell flex="0 0 8rem">+</TableCell>
          <TableCell>Total before tip</TableCell>
          <TableCell flex="1 0 0" />
          <TableCell>
            <CellPriceEditor
              error={hasPreTipTotalError}
              price={state.preTipTotal}
              minDecimals={2}
              onChange={(newValue) => {
                chain(
                  { ...state, preTipTotal: newValue },
                  calculateTipAndTotal,
                  setState,
                );
              }}
            />
          </TableCell>
        </TableRow>

        {hasPreTipTotalError && (
          <Flex
            flex="1 0 0"
            y="4rem 8rem"
            x="4rem center"
            data-error
            className={css`
              margin: 16rem 0;
              text-align: center;
            `}
          >
            <Balancer>
              The subtotal is <Price>{state.subtotalFromReceipt}</Price>, so the
              total before tip should be equal to or higher than that...
            </Balancer>
            <Balancer>
              Need help finding the total before tip? It's just the total on
              your receipt (before adding tip).
            </Balancer>
          </Flex>
        )}

        <Hr />

        <TableRow>
          <TableCell flex="0 0 8rem" />
          <TableCell>Total</TableCell>
          <TableCell flex="1 0 0" />
          <TableCell>
            <CellPriceEditor price={state.computedTotal} minDecimals={2} />
          </TableCell>
        </TableRow>
      </Section>
    </BorderedBox>
  );
};

export default TipCalculator;
