import { css } from "@emotion/css";
import Flex from "./Flex";

const BorderedBox: React.FC<{
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
}> = (props) => {
  return (
    <Flex
      as={props.as ?? "i"}
      y="2em stretch 1.25em"
      x="0.5em center"
      className={css`
        border: 1.5px solid black;
        text-align: center;
      `}
    >
      {props.children}
    </Flex>
  );
};

export default BorderedBox;
