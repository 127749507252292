import { css } from "@emotion/css";

const Checkbox: React.FC<{
  checked?: boolean;
}> = (props) => {
  return (
    <div
      className={css`
        font-size: 1.5em;
        line-height: 1em;
        vertical-align: middle;
      `}
    >
      {props.checked ? <>☑</> : <>☐</>}
    </div>
  );
};

export default Checkbox;
