import Data from "../data/types/Data";
import { b64ToUnicode, unicodeToB64 } from "./unicode-b64";

export function serializeDataV1(data: Data) {
  let json = JSON.stringify(data);
  return unicodeToB64(json).replace(/=+$/, "");
}

export function deserializeDataV1(encoded: string) {
  let json = b64ToUnicode(encoded);
  return JSON.parse(json);
}
