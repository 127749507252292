import { css } from "@emotion/css";
import React from "react";
import getPeopleAndGroupsByIds from "../data/groupsAndPeople/getPeopleAndGroupsByIds";
import getPeople from "../data/people/getPeople";
import Group from "../data/types/Group";
import Person from "../data/types/Person";
import { useDataStorage } from "../data/useDataStorage";
import Flex from "../visual/Flex";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";
import TableCell from "../visual/TableCell";
import TextButton from "../visual/TextButton";
import GroupOrPersonRow from "./GroupOrPersonRow";

export const GroupsAndPeopleSelectorTable: React.FC<{
  placeholder: string;
  label: string;
  isExpanded: boolean;
  onChangeExpanded?: (expanded: boolean) => void;
  groupsAndPeople: (Person | Group)[];
  selectedPersonAndGroupIds: number[];
  onChangePersonOrGroupSelected?: (
    personOrGroupId: number,
    selected: boolean,
  ) => void;
  error?: true | undefined;
}> = (props) => {
  const storage = useDataStorage();
  const isEditing = storage.isEditing || undefined;
  const modal = useModalWindow();

  const { people: selectedPeople, groups: selectedGroups } = storage.getData(
    getPeopleAndGroupsByIds,
    props.selectedPersonAndGroupIds,
  );
  const selectedGroupsAndPeople = [...selectedGroups, ...selectedPeople];

  const selectedGroupsAndPeopleAsString =
    selectedGroupsAndPeople.length > 0
      ? selectedGroupsAndPeople.map((groupOrPerson) => (
          <span key={groupOrPerson.id}>[{groupOrPerson.initials}]</span>
        ))
      : isEditing
        ? props.placeholder
        : null;

  return props.isExpanded ? (
    <Flex
      flex="1 0 0"
      y
      className={css`
        margin-left: 8rem;
      `}
    >
      <TableCell>
        <Flex flex="1 0 0">
          <i>{props.label}</i>
        </Flex>
        <Flex>
          <TextButton onClick={() => props.onChangeExpanded?.(false)}>
            Done
          </TextButton>
        </Flex>
      </TableCell>

      {props.groupsAndPeople.map((personOrGroup) => {
        const isSelected = props.selectedPersonAndGroupIds.includes(
          personOrGroup.id,
        );
        return (
          <GroupOrPersonRow
            key={personOrGroup.id}
            placeholder="Guest"
            style="selector"
            personOrGroup={personOrGroup}
            price={0}
            checked={isSelected}
            onChangeChecked={
              isEditing &&
              (() =>
                props.onChangePersonOrGroupSelected?.(
                  personOrGroup.id,
                  !isSelected,
                ))
            }
          />
        );
      })}
    </Flex>
  ) : (
    <TableCell>
      <Flex y="center">
        {isEditing ? (
          <TextButton
            onClick={
              isEditing &&
              (async () => {
                if (storage.getData(getPeople).length >= 1) {
                  props.onChangeExpanded?.(true);
                } else {
                  const shouldContinue = await modal.confirm(
                    [
                      "Add people in the People section above.",
                      "Then, you can use this button to choose who got each item.",
                    ].join("\n\n"),
                    {
                      okText: "Go to People",
                    },
                  );

                  if (shouldContinue) {
                    document
                      .querySelector("#people-table")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }
                }
              })
            }
            data-error={props.error || undefined}
          >
            <span>{selectedGroupsAndPeopleAsString}</span>
          </TextButton>
        ) : (
          <i>{selectedGroupsAndPeopleAsString}</i>
        )}
      </Flex>
    </TableCell>
  );
};
