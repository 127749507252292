import { useDataStorage } from "../data/useDataStorage";
import deleteEmptyRecords from "../data/validation/deleteEmptyRecords";
import isEmpty from "../data/validation/isEmpty";
import updateLocationToShareUrl from "../sharing/updateLocationToShareUrl";

export function useSave() {
  const storage = useDataStorage();

  return async function save() {
    let data = await storage.setData(deleteEmptyRecords);
    if (isEmpty(data)) {
      updateLocationToShareUrl(undefined);
    } else {
      updateLocationToShareUrl(data);
      storage.setIsEditing(false);
    }

    storage.setHighlightErrors(false);
  };
}
