import { useDataState } from "../context/dataState";
import { useUiState } from "../context/uiState";
import Data from "./types/Data";

export type DataGetTransform<P extends unknown[], R> = (
  data: Data,
  ...rest: P
) => R;

export type DataSetTransform<P extends unknown[]> = DataGetTransform<P, Data>;

export function useDataStorage() {
  const [data, setData] = useDataState();
  const uiState = useUiState();

  (window as any).data = data;

  return {
    ...uiState,

    data,

    getData<P extends unknown[], R>(f: DataGetTransform<P, R>, ...rest: P): R {
      return f(data, ...rest);
    },

    setData<P extends unknown[]>(
      f: DataSetTransform<P>,
      ...rest: P
    ): Promise<Data> {
      return new Promise((resolve) =>
        setData((data) => {
          const newData = f(data, ...rest);
          resolve(newData);
          return newData;
        }),
      );
    },
  };
}
