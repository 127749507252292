import autoNameGroup from "../data/groups/autoNameGroup";
import getUserGroups from "../data/groups/getUserGroups";
import getVenmo from "../data/payment/getVenmo";
import getPeople from "../data/people/getPeople";
import getReceipts from "../data/receipts/getReceipts";
import Data from "../data/types/Data";
import Group from "../data/types/Group";
import Item from "../data/types/Item";
import Person from "../data/types/Person";
import Receipt from "../data/types/Receipt";
import { b64ToUnicode, unicodeToB64 } from "./unicode-b64";

function objectToTableRow<T>(object: T, ...columns: ((object: T) => string)[]) {
  let table = columns.map((column) => column(object));
  return table;
}

function tableRowToObject<T>(
  tableRow: string[],
  createObject: (values: string[]) => T,
) {
  return createObject(tableRow);
}

function dataToTable(data: Data) {
  const table: string[][] = [];

  table.push(objectToTableRow(data, (data) => getVenmo(data)));

  table.push([]);

  for (let person of getPeople(data)) {
    table.push(
      objectToTableRow(
        person,
        (person) => person.id.toString(),
        (person) => person.initials,
        (person) => person.name,
        (person) => person.computedTotal.toFixed(0),
      ),
    );
  }

  table.push([]);

  for (let group of getUserGroups(data)) {
    table.push(
      objectToTableRow(
        group,
        (group) => group.id.toString(),
        (group) => group.initials,
        (group) => group.name,
        (group) => group.computedTotal.toFixed(0),
        (group) => group.personIds.join(","),
      ),
    );
  }

  table.push([]);

  for (let receipt of getReceipts(data)) {
    table.push(
      objectToTableRow(
        receipt,
        (receipt) => receipt.id.toString(),
        (receipt) => receipt.name,
        (receipt) => receipt.computedSubtotal.toFixed(0),
        (receipt) => receipt.total.toFixed(0),
      ),
    );
    for (let itemId of receipt.itemIds) {
      let item = data.Item[itemId];
      table.push(
        objectToTableRow(
          item,
          (item) => item.id.toString(),
          (item) => item.name,
          (item) => item.price.toFixed(0),
          (item) => item.personIds.join(","),
        ),
      );
    }

    table.push([]);
  }

  return table;
}

function rowHasData(row: string[] | undefined) {
  return row && (row.length > 1 || Boolean(row[0]));
}

function ensureRowEmpty(row: string[]) {
  if (rowHasData(row)) {
    throw new Error("Expected empty row");
  }
}

function tableToData(table: string[][]) {
  console.log(table);

  let i = 0;

  let venmo = tableRowToObject(table[i++], ([venmo]) => venmo);

  ensureRowEmpty(table[i++]);

  let people: Person[] = [];
  while (rowHasData(table[i])) {
    people.push(
      tableRowToObject(table[i++], ([id, initials, name, computedTotal]) => ({
        id: parseInt(id),
        initials,
        name,
        computedTotal: parseFloat(computedTotal),
      })),
    );
  }

  ensureRowEmpty(table[i++]);

  let groups: Group[] = [];
  while (rowHasData(table[i])) {
    groups.push(
      tableRowToObject(
        table[i++],
        ([id, initials, name, computedTotal, personIds]) => ({
          id: parseInt(id),
          initials,
          name,
          computedName: "",
          computedTotal: parseFloat(computedTotal),
          personIds: personIds
            .split(",")
            .filter(Boolean)
            .map((id) => parseInt(id)),
        }),
      ),
    );
  }

  ensureRowEmpty(table[i++]);

  let receipts: Receipt[] = [];
  let items: Item[] = [];
  while (rowHasData(table[i])) {
    let receipt: Receipt = tableRowToObject(
      table[i++],
      ([id, name, computedSubtotal, total]) => ({
        id: parseInt(id),
        name,
        computedSubtotal: parseFloat(computedSubtotal),
        total: parseFloat(total),
        itemIds: [],
      }),
    );
    receipts.push(receipt);

    while (rowHasData(table[i])) {
      let item = tableRowToObject(
        table[i++],
        ([id, name, price, personIds]) => {
          return {
            id: parseInt(id),
            name,
            price: parseFloat(price),
            personIds: personIds
              .split(",")
              .filter(Boolean)
              .map((id) => parseInt(id)),
          };
        },
      );
      items.push(item);
      receipt.itemIds.push(item.id);
    }

    ensureRowEmpty(table[i++]);
  }

  ensureRowEmpty(table[i++]);

  let data: Data = {
    venmo,
    personIds: people.map((person) => person.id),
    groupIds: groups.map((group) => group.id),
    receiptIds: receipts.map((receipt) => receipt.id),

    Person: Object.fromEntries(people.map((person) => [person.id, person])),
    maxPersonId: Math.max(0, ...people.map((person) => person.id)),
    Group: Object.fromEntries(groups.map((group) => [group.id, group])),
    maxGroupId: Math.min(0, ...groups.map((group) => group.id)),
    Receipt: Object.fromEntries(
      receipts.map((receipt) => [receipt.id, receipt]),
    ),
    maxReceiptId: Math.max(0, ...receipts.map((receipt) => receipt.id)),
    Item: Object.fromEntries(items.map((item) => [item.id, item])),
    maxItemId: Math.max(0, ...items.map((item) => item.id)),
  };

  for (let groupId of data.groupIds) {
    data = autoNameGroup(data, groupId);
  }

  console.log(data);
  return data;
}

function addSeparator(input: string, n: number, separator: string) {
  let result = "";
  for (let i = 0; i < input.length; i++) {
    result += input[i];
    if ((i + 1) % n === 0 && i !== input.length - 1) {
      result += separator;
    }
  }

  return result;
}

export function serializeDataV2(data: Data) {
  let table = dataToTable(data);
  let string = table.map((row) => row.join("\t")).join("\n");
  // We separate every 256 characters of the base64 string by a _ character. On
  // iMessage, links with more than 301 consecutive alphanumeric characters
  // don't get recognized as links.
  return addSeparator(unicodeToB64(string).replace(/=+$/, ""), 256, "_");
}

export function deserializeDataV2(encoded: string) {
  let string = b64ToUnicode(encoded.replace(/_/g, ""));
  let table = string.split("\n").map((row) => row.split("\t"));
  return tableToData(table);
}
