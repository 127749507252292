import { useModalState } from "../../context/modalState";
import ModalWindow from "./ModalWindow";
import { ModalWindowOptions } from "./ModalWindowProps";

/**
 * Replace alert, confirm, and prompt alert with a modal window.
 */
export function useModalWindow() {
  const [data, setData] = useModalState();

  const closeModal = () => setData({ type: undefined });

  const node = <ModalWindow {...data} />;

  return {
    node,
    alert(text: string | React.FC, options: ModalWindowOptions = {}) {
      let result = new Promise<true | undefined>((resolve) => {
        setData({ type: "alert", text, resolve, options });
      });
      result.then(closeModal);
      return result;
    },
    confirm(text: string | React.FC, options: ModalWindowOptions = {}) {
      let result = new Promise<true | undefined>((resolve) => {
        setData({ type: "confirm", text, resolve, options });
      });
      result.then(closeModal);
      return result;
    },
    prompt(
      text: string,
      defaultValue: string,
      options: ModalWindowOptions = {},
    ) {
      let result = new Promise<string | undefined>((resolve) => {
        setData({
          type: "prompt",
          text,
          defaultValue,
          resolve,
          options,
        });
      });
      result.then(closeModal);
      return result;
    },
  };
}
