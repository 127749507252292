import countFrequency from "./arrays/countFrequency";
import getUserGroupsAndPeople from "./groupsAndPeople/getUserGroupsAndPeople";
import Data from "./types/Data";
import Group from "./types/Group";

/**
 * Calculate unique initials for each person.
 */
export default function recalculateInitials(data: Data) {
  let groupsAndPeople = getUserGroupsAndPeople(data);
  let initialsMap: Record<string, string> = {};

  const namesWeHaveToCalculateInitialsFor = [];

  // In some cases, the user has already specified initials.
  for (let groupOrPerson of groupsAndPeople) {
    let name = groupOrPerson.name;
    if (groupOrPerson.id <= 0 && name === "") {
      name = (groupOrPerson as Group).computedName;
    }

    // If there are no letters, use a space.
    if (name === "") {
      initialsMap[name] = " ";
      continue;
    }

    // If there are brackets, use the letters inside the brackets.
    let m = name.match(/^\[([^\]]+)\]/);
    if (m) {
      initialsMap[name] = m[1];

      // This prevents other names from using this initial.
      // TODO: Feels hacky, check if this is actually robust.
      namesWeHaveToCalculateInitialsFor.push(m[1]);
      continue;
    }

    namesWeHaveToCalculateInitialsFor.push(name);
  }
  const hardcodedInitialsMap = { ...initialsMap };

  // If everyone starts with a different letter, just use single-letter initials.
  let firstLetters = namesWeHaveToCalculateInitialsFor.map((name) => name[0]);
  if (new Set(firstLetters).size === firstLetters.length) {
    for (let name of namesWeHaveToCalculateInitialsFor) {
      initialsMap[name] = name[0] ?? "";
    }
  } else {
    // Then we try a few other simple approaches:
    for (let name of namesWeHaveToCalculateInitialsFor) {
      // If there are at least two words, use the first letter of each word.
      let words = name.split(/\s+/g);
      if (words.length >= 2) {
        initialsMap[name] = words[0][0] + words[1][0];
        continue;
      }

      // If there are at least two capitalized letters, use those.
      let firstTwoCapitalizedLetters = Array.from(name)
        .filter((c) => c === c.toLocaleUpperCase())
        .slice(0, 2)
        .join("");
      if (firstTwoCapitalizedLetters.length >= 2) {
        initialsMap[name] = firstTwoCapitalizedLetters;
        continue;
      }

      initialsMap[name] = name.slice(0, 2);
    }
  }

  // Group all names with the same initial together, so we can see if there are
  // any initials collisions.
  let initialsToNames: Record<string, string[]> = {};
  for (let name of Object.keys(initialsMap)) {
    let initials = initialsMap[name];
    if (initialsToNames[initials] == null) {
      initialsToNames[initials] = [];
    }
    initialsToNames[initials].push(name);
  }

  // For each name in each collision set, try to find a second letter that
  // doesn't appear in any other name in the collision set.
  for (let [initials, names] of Object.entries(initialsToNames)) {
    if (names.length <= 1) {
      delete initialsToNames[initials];
      continue;
    }
    // console.log(1, names);
    // Calculate counts for all non-first letters.
    let letters = names
      .map((name) => name.slice(1).replace(/\s+/g, ""))
      .join("");
    let letterCounts = countFrequency(letters);

    // Pick the first unique second letter.
    // let fixedNames: string[] = [];
    for (let name of names) {
      // console.log("trying to fix", name);
      for (let i = 1; i < name.length; i++) {
        let letter = name[i];
        if (letterCounts[letter] === 1) {
          initialsMap[name] = name[0] + letter;

          // Remove from the collision set.
          // fixedNames.push(name);
          break;
        }
      }
    }

    // console.log("fixed", fixedNames);
    // names = names.filter((name) => !fixedNames.includes(name));
    // initialsToNames[initials] = names;
  }

  // For each remaining collision set, do a diff and output the first
  // differing letter.

  for (let [initials, names] of Object.entries(initialsToNames)) {
    if (names.length <= 1) {
      delete initialsToNames[initials];
      continue;
    }
    // console.log(2, names);
    for (let i = 1; i < Math.max(...names.map((name) => name.length)); i++) {
      let fixedNames: string[] = [];
      let letters = names.map((name) => name[i]).filter((c) => c);
      // console.log("letters", letters);
      let letterCounts = countFrequency(letters);
      for (let name of names) {
        let letter = name[i];
        if (letterCounts[letter] === 1) {
          initialsMap[name] = name[0] + letter;

          // Remove from the collision set.
          fixedNames.push(name);
        }
      }
      // console.log("fixed", i, fixedNames);
      names = names.filter((name) => !fixedNames.includes(name));
      initialsToNames[initials] = names;
    }
  }

  initialsMap = { ...initialsMap, ...hardcodedInitialsMap };

  // Update the initials.
  let Person: typeof data.Person = {};
  for (let person of Object.values(data.Person)) {
    Person[person.id] = {
      ...person,
      initials: initialsMap[person.name],
    };
  }

  let Group: typeof data.Group = {};
  for (let group of Object.values(data.Group)) {
    Group[group.id] = {
      ...group,
      initials: initialsMap[group.name || group.computedName],
    };
  }

  return {
    ...data,
    Person,
    Group,
  };
}
