export default async function resizeImage(imgFile: File, maxFileSize: number) {
  const maxQuality = 1.0;
  const scaleStep = 0.3;
  const qualityStep = 0.2;

  const img = new Image();

  const imgLoaded = new Promise((resolve) => (img.onload = resolve));
  img.src = URL.createObjectURL(imgFile);
  await imgLoaded;

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Cannot get 2d canvas context");
  }

  let currentQuality = maxQuality;
  let scale = 1;

  while (scale > 0 && currentQuality > 0) {
    console.log("Resizing image", scale, currentQuality);
    canvas.width = img.width * scale;
    canvas.height = img.height * scale;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    const blob: Blob | null = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/jpeg", currentQuality),
    );
    if (!blob) {
      throw new Error("Failed to create blob");
    }

    const blobSize = blob.size;

    if (blobSize <= maxFileSize) {
      return new File([blob], imgFile.name);
    }

    currentQuality -= qualityStep;
    scale -= scaleStep;
  }

  throw new Error("Error resizing image.");
}
