import chain from "../function/chain";
import recalculateInitials from "../recalculateInitials";
import Data from "../types/Data";
import addOrUpdateGroup from "./addOrUpdateGroup";
import autoNameGroup from "./autoNameGroup";

export default function updateGroupName(
  data: Data,
  groupId: number,
  name: string | undefined,
): Data {
  const newPartialGroup =
    name == null
      ? {
          id: groupId,
        }
      : {
          id: groupId,
          name: name.trim(),
        };

  return chain(
    data,
    (data) => addOrUpdateGroup(data, "update", newPartialGroup),
    (data) => autoNameGroup(data, groupId),
    recalculateInitials,
  );
}
