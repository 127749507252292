import { useModalWindow } from "../../visual/ModalWindow/useModalWindow";
import setInArray from "../arrays/setInArray";
import getAllGroupsById from "../groups/getAllGroupsById";
import recalculateTotals from "../recalculateTotals";
import Data from "../types/Data";

export default function toggleItemPersonOrGroup(
  data: Data,
  itemId: number,
  personOrGroupId: number,
  value: boolean | undefined = undefined,
  modal: ReturnType<typeof useModalWindow>,
): Data {
  const Group = getAllGroupsById(data);

  const item = data.Item[itemId];

  let personIds = item.personIds;

  personIds = setInArray(personIds, personOrGroupId, value);

  // For now, disallow selecting multiple groups. If we're trying to add a
  // group, remove all other groups.
  if (personOrGroupId <= 0) {
    const newPersonIds = personIds.filter(
      (somePersonOrGroupId) =>
        somePersonOrGroupId === personOrGroupId || somePersonOrGroupId > 0,
    );
    if (personIds.length !== newPersonIds.length) {
      modal.alert(
        [
          "You can only select one group at a time.",
          "We're still working on the ability to select multiple groups per item.",
          "If you would like BillJoy to support selecting multiple groups, please let us know using the feedback link at the bottom of the page.",
        ].join("\n\n"),
      );
    }
    personIds = newPersonIds;
  }

  // If we're toggling a group that contains a person who is already
  // assigned to this item, remove that person from the item.
  if (personOrGroupId <= 0) {
    let group = Group[personOrGroupId];
    personIds = personIds.filter(
      (personId) => !group.personIds.includes(personId),
    );
  }

  // If we're toggling a person who is part of a group that is already
  // assigned to this item, remove that group from the item.
  if (personOrGroupId > 0) {
    let personIdsToDeselect: number[] = [];
    for (let everyPersonOrGroupId of item.personIds) {
      if (everyPersonOrGroupId > 0) continue;

      let group = Group[everyPersonOrGroupId];
      if (group.personIds.includes(personOrGroupId)) {
        personIdsToDeselect.push(everyPersonOrGroupId);
      }
    }
    personIds = personIds.filter(
      (personId) => !personIdsToDeselect.includes(personId),
    );
  }

  return recalculateTotals({
    ...data,
    Item: {
      ...data.Item,
      [itemId]: {
        ...item,
        personIds,
      },
    },
  });
}
