import Data from "../types/Data";

export default function addReceipt(data: Data, name: string): Data {
  const id = data.maxReceiptId + 1;
  const newReceipt = {
    id,
    name,
    computedSubtotal: 0,
    total: 0,
    itemIds: [],
  };

  return {
    ...data,
    receiptIds: [...data.receiptIds, id],
    Receipt: {
      ...data.Receipt,
      [id]: newReceipt,
    },
    maxReceiptId: id,
  };
}
