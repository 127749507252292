import deleteGroupOrPerson from "../data/groupsAndPeople/deleteGroupOrPerson";
import Group from "../data/types/Group";
import Person from "../data/types/Person";
import { useDataStorage } from "../data/useDataStorage";
import { useModalWindow } from "../visual/ModalWindow/useModalWindow";

export default function useDeleteGroupOrPerson() {
  const modal = useModalWindow();
  const storage = useDataStorage();

  return async function deleteGroupOrPersonFlow(personOrGroup: Person | Group) {
    const noun = personOrGroup.id > 0 ? "person" : "group";

    const result = await modal.confirm(
      [
        //
        `Delete this ${noun}?`,
        personOrGroup.name || (personOrGroup.computedName ?? ""),
        `Tip: To add a new ${noun} anywhere, select a name and press Enter.`,
      ].join("\n\n"),
      {
        danger: true,
        okText: "Delete " + noun,
      },
    );

    if (result) {
      storage.setData(deleteGroupOrPerson, personOrGroup.id);
    }
  };
}
