import React from "react";
import addPerson from "../data/people/addPerson";
import getPeople from "../data/people/getPeople";
import hasPeople from "../data/people/hasPeople";
import updatePersonName from "../data/people/updatePersonName";
import hasReceipts from "../data/receipts/hasReceipts";
import { useDataStorage } from "../data/useDataStorage";
import TableCell from "../visual/TableCell";
import TableRow from "../visual/TableRow";
import TextButton, { GlyphAdd } from "../visual/TextButton";
import GroupOrPersonRow from "./GroupOrPersonRow";

const PeopleTable: React.FC<unknown> = () => {
  const storage = useDataStorage();
  const isEditing = storage.isEditing || undefined;

  return (
    <>
      {isEditing && storage.getData(hasPeople) && (
        <TableRow>
          <TableCell>
            <TextButton onClick={() => storage.setData(addPerson, {}, 0)}>
              <GlyphAdd />
            </TextButton>
          </TableCell>
        </TableRow>
      )}

      {storage.getData(getPeople).map((person, i) => (
        <GroupOrPersonRow
          key={person.id}
          placeholder="Guest"
          style="total"
          personOrGroup={person}
          onChangeName={
            isEditing &&
            ((name) => {
              storage.setData(updatePersonName, person.id, name);
            })
          }
          onKeyPressEnter={
            isEditing && (() => storage.setData(addPerson, {}, i + 1))
          }
          price={person.computedTotal}
        />
      ))}

      {isEditing && (
        <TableRow>
          <TableCell>
            <TextButton onClick={() => storage.setData(addPerson, {})}>
              {storage.getData(hasPeople) ? (
                <GlyphAdd />
              ) : (
                <>
                  <GlyphAdd />
                  {storage.getData(hasReceipts) ? (
                    <>Don't forgot to input the guests!</>
                  ) : (
                    <>Step 1: Input who's splitting</>
                  )}
                </>
              )}
            </TextButton>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default PeopleTable;
