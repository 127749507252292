import { z } from "zod";

export const ItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  price: z.number(),
  personIds: z.array(z.number()),
});

type Item = z.infer<typeof ItemSchema>;

export default Item;
