import { css } from "@emotion/css";
import React from "react";
import Flex from "../visual/Flex";
import CellTextEditor from "./CellTextEditor";

const CellPriceEditor: React.FC<{
  price: number;
  minDecimals: 0 | 2;
  error?: boolean;
  onChange?: (value: number) => void;
  muted?: boolean;
}> = (props) => {
  let minDecimals = props.minDecimals === 0 && props.price % 100 === 0 ? 0 : 2;
  let displayValue = (props.price / 100).toFixed(minDecimals);

  return (
    <Flex
      x="0 0.5ch"
      y="center"
      className={css`
        width: 8.5ch;

        input {
          background: ${props.muted ? "none" : undefined};
          box-shadow: ${props.muted ? "none" : undefined};
          outline: ${props.muted ? "none" : undefined};
        }
      `}
    >
      <span>$</span>
      <CellTextEditor
        type="number-hundredths"
        placeholder={displayValue}
        value=""
        align="right"
        error={props.error}
        userSelectAll
        onChange={
          props.onChange &&
          ((valueAsString) => {
            if (!props.onChange) return;

            const valueAsNumber = parseFloat(valueAsString);
            if (isNaN(valueAsNumber)) return;
            props.onChange(valueAsNumber * 100);
          })
        }
      >
        {props.price === 0 ? undefined : displayValue}
      </CellTextEditor>
    </Flex>
  );
};
export default CellPriceEditor;
