import { z } from "zod";

export const ReceiptSchema = z.object({
  id: z.number(),
  name: z.string(),
  computedSubtotal: z.number(),
  total: z.number(),
  itemIds: z.array(z.number()),
});

type Receipt = z.infer<typeof ReceiptSchema>;

export default Receipt;
