import { I } from "./I";

const Logo: React.FC = () => {
  return (
    <>
      <div />

      <I>
        <div>| |</div>
        <br />
        <div>\_____/</div>
      </I>

      <div />
    </>
  );
};

export default Logo;
