import { z } from "zod";

export const GroupSchema = z.object({
  id: z.number(),
  name: z.string(),
  initials: z.string(),
  personIds: z.array(z.number()),
  computedTotal: z.number(),

  computedName: z.string().default(""), // [1]]
});

// [1]: Group auto-naming added on 2023-07-29 in 62f9e96.

type Group = z.infer<typeof GroupSchema>;

export default Group;
